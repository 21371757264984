<template>
	<div>
  		<input type="file" v-bind:accept="accept" v-on:change="loadFile" v-bind:multiple="multiple">
  	</div>
</template>

<script>
	export default {
	  	props: {
		    value: {
				required: true
		    },
		    multiple:false,
		    accept:null
		},
	  	methods:{
	  		loadFile($event){
				const files = $event.target.files || $event.dataTransfer.files
				var aux=[];
				for(var i=0;i<files.length;i++){
					aux.push(files[i]);
				}
				this.$emit('input', aux)
	  		}
	  	}
	}
</script>