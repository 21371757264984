<template>
	<div class="modal fade action-sheet opax" id="startContractModal" tabindex="-1" role="dialog">
	    <div class="modal-dialog modal-md" role="document">
	    	<div class="modal-content">
			    <div class="modal-header">
				    <h5 class="modal-title">Nuevo expediente</h5>
				    <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-on:click="cancel();">
				    <span aria-hidden="true">&times;</span>
				    </button>
				</div>
				<div class="modal-body">
					<div class="row">
						<div class="col-12">
	                        <label>Empresa *</label>
	                        <select type="text" class="form-control" v-model="contract.id_company" v-bind:class="{'is-invalid':errors.id_company}">
	                            <option v-for="c in companies" v-bind:value="c.id">{{ c.name }}</option>
	                        </select>
	                    </div>
                    
                        <div class="col-12">
                            <label>Delegación *</label>
                            <input spellcheck="true" type="text" class="form-control" v-model="contract.delegation"  placeholder="Delegación" v-bind:class="{'is-invalid':errors.delegation}"/>
                        </div>
                        <div class="col-12">
                            <label>Provincia *</label>
                            <autocomplete-component v-model="contract.id_state" v-bind:options="states" v-bind:key-code="'id'" v-bind:key-label="'name'" v-bind:class="{'is-invalid':errors.id_state}"></autocomplete-component>
                            
                        </div>
                        <!--<div class="col-12">
                            <label>Área *</label>
                            <input spellcheck="true" type="text" class="form-control" v-model="contract.area"  placeholder="Área" v-bind:class="{'is-invalid':errors.area}"/>
                        </div>
                        <div class="col-12">
                            <label>Zona *</label>
                            <input spellcheck="true" type="text" class="form-control" v-model="contract.zone"  placeholder="Zona" v-bind:class="{'is-invalid':errors.zone}"/>
                        </div>-->
                        <div class="col-12">
                            <label>Tipo de carpeta *</label>
                            <select type="text" class="form-control" v-model="contract.type_of_folder" v-bind:class="{'is-invalid':errors.type_of_folder}">
	                            <option value="expedient">Expediente</option>
                                <option value="sickness">Enferetramiento</option>
	                            <option value="precontract">Precontratado</option>
	                            <option value="budget">Presupuesto</option>
	                            
	                        </select>
                        </div>
                        <div class="col-12">
                            <label>Tipo de expediente *</label>
                            <select type="text" class="form-control" v-model="contract.type_of_expedient" v-bind:class="{'is-invalid':errors.type_of_expedient}">
	                            <option value="complete">Completo</option>
                                <option value="reception">Recepción</option>
	                            <option value="partial">Donaciones, miembros y fetos</option>
                                <option value="others">Otros (Enferetramiento)</option>
	                        </select>
                        </div>
                    </div>
				</div>
				<div class="modal-footer">
				    <button type="button" class="btn btn-success mr-2" v-on:click="save();" v-bind:disabled="saving"><i class="fa fa-spin fa-spinner" v-show="saving"></i> Guardar</button>
				    <button type="button" class="btn btn-danger" data-dismiss="modal" v-on:click="cancel();">Cancelar</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
    export default {
        mounted() {
        	this.$root.$on('contracts.add',this.openModal);
            this.getContext();
        },
        data(){
            return {
            	base: window.urls['contracts.index'],
                saving: false,
                companies: [],
                states: [],
                contract:{
                    id_company: null,
					delegation: "Madrid",
					id_state: 1,
					area: null,
					zone: null,
					type_of_folder: null,
					type_of_expedient: null
                },
                errors: {
					id_company: false,
					delegation: false,
					id_state: false,
					area: false,
					zone: false,
					type_of_folder: false,
					type_of_expedient: false
                },
            }
        },
        methods:{
            getContext(){
                var vm = this;
                axios({
                    method: 'get',
                    url: this.base + '/context',
                    responseType: 'json',
                }).then(function(response){
                    vm.loading = false;
                    vm.$root.$emit('contracts.context', response.data);
                    vm.companies = response.data.companies;
                    vm.states = response.data.states;
                })
                .catch(function (error) {
                    console.log(error)
                    vm.saving = false;
                    var message = 'No se pudo cargar la información del servidor';
                    vm.$swal(
                        '¡Ups!',
                        message,
                        'error'
                    );
                });
            },
        	openModal(data){
                if(data){
                    this.contract={
                        id: data.id,
                        id_company: data.id_company,
                        delegation: data.delegation,
                        id_state: data.id_state,
                        area: data.area,
                        zone: data.zone,
                        type_of_folder: data.type_of_folder,
                        type_of_expedient: data.type_of_expedient
                    };
                }else{
                    this.contract={
                        id_company: null,
						delegation: "Madrid",
						id_state: 1,
						area: null,
						zone: null,
						type_of_folder: null,
						type_of_expedient: null,
                    };
                    
                }
                this.resetErrors();
        		$('#startContractModal').modal({'backdrop':'static'});
        	},
            resetErrors(){
                this.errors={
					id_company: false,
					delegation: false,
					id_state: false,
					area: false,
					zone: false,
					type_of_folder: false,
					type_of_expedient: false
                };
            },
            validate(){
                var valid = true;

                this.errors.id_company = this.contract.id_company == null || this.contract.id_company == "";
				this.errors.delegation = this.contract.delegation == null || this.contract.delegation == "";
				this.errors.id_state = this.contract.id_state == null || this.contract.id_state == "";
				//this.errors.area = this.contract.area == null || this.contract.area == "";
				//this.errors.zone = this.contract.zone == null || this.contract.zone == "";
				this.errors.type_of_folder = this.contract.type_of_folder == null || this.contract.type_of_folder == "";
				this.errors.type_of_expedient = this.contract.type_of_expedient == null || this.contract.type_of_expedient == "";

                for(var i in this.errors)
                    valid &= !this.errors[i];
                if(!valid)
                    this.showRequiredMessage();
                return valid;
            },
            showRequiredMessage(){
                this.$swal(
                    'Atención',
                    'Hay datos obligatorios por rellenar',
                    'warning'
                );
            },
            save(){
                if(!this.validate())
                    return;
                var url=this.base;
                var vm=this;
                if(this.contract.id) url += '/' + this.contract.id;
                console.log(this.contract);
                this.saving=true;
                axios({
                    method: 'post',
                    url: url,
                    data: {
                        contract: this.contract
                    },
                    responseType: 'json',
                }).then(function(response){
                    vm.saving=false;
                    vm.$root.$emit('contracts.updated');
                    vm.$root.$emit('contracts.edit', response.data);
                    vm.cancel();
                })
                .catch(function (error) {
                    vm.saving = false;
                    var message = 'Parece que algo fue mal';
                    if(error.response.status == 400)
                        message = error.response.data.message;
                    if(error.response.status == 404)
                        message = "El registro ya no existe";
                    vm.$swal(
                      '¡Ups!',
                      message,
                      'error'
                    );
                });
            },
            cancel(){
            	$('#startContractModal').modal('hide');
            }
        }
    }
</script>
