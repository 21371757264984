
<template>
	<div class="row">
        <div class="col-12 col-md">
			<label>Estado</label>
			<select class="form-control" v-model="contract.status">
				<option value="assigned">Asignado</option>
				<option value="processing">En curso</option>
				<option value="contracted">Pendiente de finalización</option>
				<option value="finished">Finalizado</option>
				<option value="review" v-if="role == 'admin'">Revisar de nuevo</option>
			</select>
		</div>
	</div>
</template>
<script>
	export default{
		props: ['contract'],
		data(){
			return {
				role: user_role
			}
		},
	}
</script>