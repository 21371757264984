<template>
	<div>
		<div class="alert alert-danger" v-if="!contract.id">
			Debes guardar el contrato antes de poder adjuntar documentos
		</div>
		<div v-if="contract.id">
			<div class="row">
				<div class="col-6 col-md text-center">
					<small class="d-block mb-3">DNI fallecido (Frontal)</small>
					<contract-form-documentation-file-component accept="image/*,application/pdf" v-model="documents.deceased_front" v-bind:initial="initialDocuments.deceased_front"></contract-form-documentation-file-component>
				</div>
				<div class="col-6 col-md text-center">
					<small class="d-block mb-3">DNI fallecido (reverso)</small>
					<contract-form-documentation-file-component accept="image/*,application/pdf" v-model="documents.deceased_back" v-bind:initial="initialDocuments.deceased_back"></contract-form-documentation-file-component>
				</div>
				<div class="col-6 col-md text-center">
					<small class="d-block mb-3">DNI solicitante (Frontal)</small>
					<contract-form-documentation-file-component accept="image/*,application/pdf" v-model="documents.solicitant_front" v-bind:initial="initialDocuments.solicitant_front"></contract-form-documentation-file-component>
				</div>
				<div class="col-6 col-md text-center">
					<small class="d-block mb-3">DNI solicitante (Reverso)</small>
					<contract-form-documentation-file-component accept="image/*,application/pdf" v-model="documents.solicitant_back" v-bind:initial="initialDocuments.solicitant_back"></contract-form-documentation-file-component>
				</div>
				<div class="col-6 col-md text-center">
					<small class="d-block mb-3">Autorización</small>
					<contract-form-documentation-file-component accept="image/*,application/pdf" v-model="documents.authorizations" v-bind:initial="initialDocuments.authorizations"></contract-form-documentation-file-component>
				</div>
			</div>
			<div class="row mt-3">
				<div class="col-6 col-md text-center">
					<small class="d-block mb-3">Recibo bancario</small>
					<contract-form-documentation-file-component accept="image/*,application/pdf" v-model="documents.bank_receipt" v-bind:initial="initialDocuments.bank_receipt"></contract-form-documentation-file-component>
				</div>
				<div class="col-6 col-md text-center">
					<small class="d-block mb-3">Certificado médico (Frontal)</small>
					<contract-form-documentation-file-component accept="image/*,application/pdf" v-model="documents.medical_certification" v-bind:initial="initialDocuments.medical_certification"></contract-form-documentation-file-component>
				</div>
				<div class="col-6 col-md text-center">
					<small class="d-block mb-3">Certificado médico (Reverso)</small>
					<contract-form-documentation-file-component accept="image/*,application/pdf" v-model="documents.medical_certification_back" v-bind:initial="initialDocuments.medical_certification_back"></contract-form-documentation-file-component>
				</div>
				<div class="col-6 col-md text-center">
					<small class="d-block mb-3">Título / Delegos</small>
					<contract-form-documentation-file-component accept="image/*,application/pdf" v-model="documents.title" v-bind:initial="initialDocuments.title"></contract-form-documentation-file-component>
				</div>
				<div class="col-6 col-md text-center">
					<small class="d-block mb-3">Licencia frontal</small>
					<contract-form-documentation-file-component accept="image/*,application/pdf" v-model="documents.license_frontal" v-bind:initial="initialDocuments.license_frontal"></contract-form-documentation-file-component>
				</div>
				<div class="col-6 col-md text-center">
					<small class="d-block mb-3">Licencia trasera</small>
					<contract-form-documentation-file-component accept="image/*,application/pdf" v-model="documents.license_back" v-bind:initial="initialDocuments.license_back"></contract-form-documentation-file-component>
				</div>
			</div>
			<hr />
			<legend class="text-center">Documentación adicional</legend>
			<div class="row mt-3">
				<div class="col-6 col-md text-center">
					<small class="d-block mb-3">Adicional 1</small>
					<contract-form-documentation-file-component accept="image/*,application/pdf" v-model="documents.aditional_1" v-bind:initial="initialDocuments.aditional_1"></contract-form-documentation-file-component>
				</div>
				<div class="col-6 col-md text-center">
					<small class="d-block mb-3">Adicional 2</small>
					<contract-form-documentation-file-component accept="image/*,application/pdf" v-model="documents.aditional_2" v-bind:initial="initialDocuments.aditional_2"></contract-form-documentation-file-component>
				</div>
				<div class="col-6 col-md text-center">
					<small class="d-block mb-3">Adicional 3</small>
					<contract-form-documentation-file-component accept="image/*,application/pdf" v-model="documents.aditional_3" v-bind:initial="initialDocuments.aditional_3"></contract-form-documentation-file-component>
				</div>
				<div class="col-6 col-md text-center">
					<small class="d-block mb-3">Adicional 4</small>
					<contract-form-documentation-file-component accept="image/*,application/pdf" v-model="documents.aditional_4" v-bind:initial="initialDocuments.aditional_4"></contract-form-documentation-file-component>
				</div>
				<div class="col-6 col-md text-center">
					<small class="d-block mb-3">Adicional 5</small>
					<contract-form-documentation-file-component accept="image/*,application/pdf" v-model="documents.aditional_5" v-bind:initial="initialDocuments.aditional_5"></contract-form-documentation-file-component>
				</div>
				<div class="col-6 col-md text-center">
					<small class="d-block mb-3">Adicional 6</small>
					<contract-form-documentation-file-component accept="image/*,application/pdf" v-model="documents.aditional_6" v-bind:initial="initialDocuments.aditional_6"></contract-form-documentation-file-component>
				</div>
			</div>
			<hr />
			<legend class="text-center">Suplidos</legend>
			<div class="row mt-3">
				<div class="col-6 col-md text-center">
					<small class="d-block mb-3">Suplidos 1</small>
					<contract-form-documentation-file-component accept="image/*,application/pdf" v-model="documents.supplied_1" v-bind:initial="initialDocuments.supplied_1"></contract-form-documentation-file-component>
				</div>
				<div class="col-6 col-md text-center">
					<small class="d-block mb-3">Suplidos 2</small>
					<contract-form-documentation-file-component accept="image/*,application/pdf" v-model="documents.supplied_2" v-bind:initial="initialDocuments.supplied_2"></contract-form-documentation-file-component>
				</div>
				<div class="col-6 col-md text-center">
					<small class="d-block mb-3">Suplidos 3</small>
					<contract-form-documentation-file-component accept="image/*,application/pdf" v-model="documents.supplied_3" v-bind:initial="initialDocuments.supplied_3"></contract-form-documentation-file-component>
				</div>
				<div class="col-6 col-md text-center">
					<small class="d-block mb-3">Suplidos 4</small>
					<contract-form-documentation-file-component accept="image/*,application/pdf" v-model="documents.supplied_4" v-bind:initial="initialDocuments.supplied_4"></contract-form-documentation-file-component>
				</div>
				<div class="col-6 col-md text-center">
					<small class="d-block mb-3">Suplidos 5</small>
					<contract-form-documentation-file-component accept="image/*,application/pdf" v-model="documents.supplied_5" v-bind:initial="initialDocuments.supplied_5"></contract-form-documentation-file-component>
				</div>
				<div class="col-6 col-md text-center">
					<small class="d-block mb-3">Suplidos 6</small>
					<contract-form-documentation-file-component accept="image/*,application/pdf" v-model="documents.supplied_6" v-bind:initial="initialDocuments.supplied_6"></contract-form-documentation-file-component>
				</div>
			</div>
			<hr v-if="user_role == 'admin'"/>
			<legend v-if="user_role == 'admin'" class="text-center">Administración</legend>
			<div v-if="user_role == 'admin'" class="row mt-3">
				<div class="col-6 col-md text-center">
					<small class="d-block mb-3">Administración 1</small>
					<contract-form-documentation-file-component accept="image/*,application/pdf" v-model="documents.admin_1" v-bind:initial="initialDocuments.admin_1"></contract-form-documentation-file-component>
				</div>
				<div class="col-6 col-md text-center">
					<small class="d-block mb-3">Administración 2</small>
					<contract-form-documentation-file-component accept="image/*,application/pdf" v-model="documents.admin_2" v-bind:initial="initialDocuments.admin_2"></contract-form-documentation-file-component>
				</div>
				<div class="col-6 col-md text-center">
					<small class="d-block mb-3">Administración 3</small>
					<contract-form-documentation-file-component accept="image/*,application/pdf" v-model="documents.admin_3" v-bind:initial="initialDocuments.admin_3"></contract-form-documentation-file-component>
				</div>
				<div class="col-6 col-md text-center">
					<small class="d-block mb-3">Administración 4</small>
					<contract-form-documentation-file-component accept="image/*,application/pdf" v-model="documents.admin_4" v-bind:initial="initialDocuments.admin_4"></contract-form-documentation-file-component>
				</div>
				<div class="col-6 col-md text-center">
					<small class="d-block mb-3">Administración 5</small>
					<contract-form-documentation-file-component accept="image/*,application/pdf" v-model="documents.admin_5" v-bind:initial="initialDocuments.admin_5"></contract-form-documentation-file-component>
				</div>
				<div class="col-6 col-md text-center">
					<small class="d-block mb-3">Administración 6</small>
					<contract-form-documentation-file-component accept="image/*,application/pdf" v-model="documents.admin_6" v-bind:initial="initialDocuments.admin_6"></contract-form-documentation-file-component>
				</div>
			</div>
			<div class="text-right">
				<a class="btn btn-primary" target="_blank" v-bind:href="base + '/print-documents/' + contract.id">Imprimir</a>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		props: ['contract'],
		mounted(){
			this.$root.$on('form.initialize.documents', this.initializeDocuments);
		},
		data(){
			return {
				base: window.urls['contracts.index'],
				front: null,
				user_role: user_role,
				documents: {
                    deceased_front: null,
                    deceased_back: null,
                    solicitant_front: null,
                    solicitant_back: null,
                    authorization: null,
                    bank_receipt: null,
                    medical_certification: null,
                    medical_certification_back: null,
                    title: null,
                    license_frontal: null,
                    license_back: null,
                    aditional_1: null,
                    aditional_2: null,
                    aditional_3: null,
                    aditional_4: null,
                    aditional_5: null,
                    aditional_6: null,
					supplied_1: null,
					supplied_2: null,
					supplied_3: null,
					supplied_4: null,
					supplied_5: null,
					supplied_6: null
                },
                initialDocuments: {
                	deceased_front: null,
                    deceased_back: null,
                    solicitant_front: null,
                    solicitant_back: null,
                    authorization: null,
                    bank_receipt: null,
                    medical_certification: null,
                    medical_certification_back: null,
                    title: null,
                    license_frontal: null,
                    license_back: null,
                    aditional_1: null,
                    aditional_2: null,
                    aditional_3: null,
                    aditional_4: null,
                    aditional_5: null,
                    aditional_6: null,
					supplied_1: null,
					supplied_2: null,
					supplied_3: null,
					supplied_4: null,
					supplied_5: null,
					supplied_6: null
                }
			};
		},
		watch: {
			documents: {
				deep: true,
                handler(data){
					this.$root.$emit('contracts.section.set.documents',this.documents);
				}
			}
		},
		methods: {
			initializeDocuments(data){
				if(data.documents){
					this.initialDocuments = data.documents;
				}
			}
		}
	}
</script>