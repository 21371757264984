/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue').default;

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('options-component', require('./components/general/OptionsComponent.vue').default);
Vue.component('input-file', require('./components/general/InputFileComponent.vue').default);
Vue.component('file-preview', require('./components/general/FilePreviewComponent.vue').default);
Vue.component('input-date', require('./components/general/DateInputComponent.vue').default);
Vue.component('input-time', require('./components/general/TimeInputComponent.vue').default);
Vue.component('autocomplete-component', require('./components/general/AutoCompleteComponent.vue').default);
Vue.component('button-component', require('./components/general/ButtonComponent.vue').default);

Vue.component('arks-table-component', require('./components/arks/TableComponent.vue').default);
Vue.component('arks-form-component', require('./components/arks/FormComponent.vue').default);
Vue.component('arks-filter-component', require('./components/arks/FilterComponent.vue').default);

Vue.component('bank-accounts-table-component', require('./components/banks/TableComponent.vue').default);
Vue.component('bank-accounts-form-component', require('./components/banks/FormComponent.vue').default);
Vue.component('bank-accounts-filter-component', require('./components/banks/FilterComponent.vue').default);

Vue.component('categories-table-component', require('./components/portfolio-categories/TableComponent.vue').default);
Vue.component('categories-form-component', require('./components/portfolio-categories/FormComponent.vue').default);
Vue.component('categories-filter-component', require('./components/portfolio-categories/FilterComponent.vue').default);

Vue.component('contract-templates-table-component', require('./components/contracts-templates/TableComponent.vue').default);
Vue.component('contract-templates-form-component', require('./components/contracts-templates/FormComponent.vue').default);
Vue.component('contract-templates-filter-component', require('./components/contracts-templates/FilterComponent.vue').default);

Vue.component('cementeries-table-component', require('./components/cementeries/TableComponent.vue').default);
Vue.component('cementeries-form-component', require('./components/cementeries/FormComponent.vue').default);
Vue.component('cementeries-filter-component', require('./components/cementeries/FilterComponent.vue').default);

Vue.component('clients-table-component', require('./components/clients/TableComponent.vue').default);
Vue.component('clients-form-component', require('./components/clients/FormComponent.vue').default);
Vue.component('clients-documents-form-component', require('./components/clients/DocumentsFormComponent.vue').default);
Vue.component('clients-filter-component', require('./components/clients/FilterComponent.vue').default);

Vue.component('coffining-table-component', require('./components/coffining/TableComponent.vue').default);
Vue.component('coffining-form-component', require('./components/coffining/FormComponent.vue').default);
Vue.component('coffining-filter-component', require('./components/coffining/FilterComponent.vue').default);

Vue.component('contacts-table-component', require('./components/contacts/TableComponent.vue').default);
Vue.component('contacts-form-component', require('./components/contacts/FormComponent.vue').default);
Vue.component('contacts-budget-form-component', require('./components/contacts/BudgetFormComponent.vue').default);
Vue.component('contacts-budget-list-component', require('./components/contacts/BudgetsListComponent.vue').default);
Vue.component('contacts-filter-component', require('./components/contacts/FilterComponent.vue').default);

Vue.component('contracts-table-component', require('./components/contracts/TableComponent.vue').default);
Vue.component('contracts-initial-form-component', require('./components/contracts/StartFormComponent.vue').default);
Vue.component('contracts-form-component', require('./components/contracts/FormComponent.vue').default);
Vue.component('contracts-share-component', require('./components/contracts/ShareComponent.vue').default);
Vue.component('contracts-filter-component', require('./components/contracts/FilterComponent.vue').default);
Vue.component('contracts-number-form-component', require('./components/contracts/ChangeNumberModal.vue').default);
Vue.component('contracts-commercial-form-component', require('./components/contracts/ChangeComercialModal.vue').default);

Vue.component('contract-form-deceased-component', require('./components/contracts/_form/_partials/DeceasedComponent.vue').default);
Vue.component('contract-form-deceasing-component', require('./components/contracts/_form/_partials/DeceasingComponent.vue').default);
Vue.component('contract-form-client-component', require('./components/contracts/_form/_partials/ClientComponent.vue').default);
Vue.component('contract-form-service-component', require('./components/contracts/_form/_partials/ServiceComponent.vue').default);
Vue.component('contract-form-mounting-component', require('./components/contracts/_form/_partials/MountingComponent.vue').default);
Vue.component('contract-form-destination-component', require('./components/contracts/_form/_partials/DestinationComponent.vue').default);
Vue.component('contract-form-documents-component', require('./components/contracts/_form/_partials/DocumentsComponent.vue').default);
Vue.component('contract-form-contracts-component', require('./components/contracts/_form/_partials/ContractsComponent.vue').default);
Vue.component('contract-form-info-component', require('./components/contracts/_form/_partials/InfoComponent.vue').default);
Vue.component('contract-form-documentation-component', require('./components/contracts/_form/_partials/DocumentationComponent.vue').default);
Vue.component('contract-form-documentation-file-component', require('./components/contracts/_form/_custom-files/FileComponent.vue').default);
Vue.component('contract-form-status-component', require('./components/contracts/_form/_partials/StatusComponent.vue').default);

Vue.component('contract-sign-document-component', require('./components/contracts/_form/SignDocumentComponent.vue').default);
Vue.component('contract-send-document-component', require('./components/contracts/_form/SendDocumentModalComponent.vue').default);
Vue.component('contract-form-customer-component', require('./components/contracts/_form/_partials/_contracts/CustomerComponent.vue').default);
Vue.component('contract-form-contract-component', require('./components/contracts/_form/_partials/_contracts/ContractComponent.vue').default);


Vue.component('contract-add-info-component', require('./components/contracts/_form/AddInformationFormComponent.vue').default);
Vue.component('contract-floral-ornaments', require('./components/contracts/_form/_partials/_floral-ornaments/FormComponent.vue').default);
Vue.component('contract-reminders', require('./components/contracts/_form/_partials/_reminders/FormComponent.vue').default);

Vue.component('floral-ornaments-table-component', require('./components/floral-ornaments/TableComponent.vue').default);
Vue.component('floral-ornaments-form-component', require('./components/floral-ornaments/FormComponent.vue').default);
Vue.component('floral-ornaments-filter-component', require('./components/floral-ornaments/FilterComponent.vue').default);

Vue.component('installations-table-component', require('./components/installations/TableComponent.vue').default);
Vue.component('installations-form-component', require('./components/installations/FormComponent.vue').default);
Vue.component('installations-filter-component', require('./components/installations/FilterComponent.vue').default);

Vue.component('insurances-table-component', require('./components/insurances/TableComponent.vue').default);
Vue.component('insurances-form-component', require('./components/insurances/FormComponent.vue').default);
Vue.component('insurances-filter-component', require('./components/insurances/FilterComponent.vue').default);

Vue.component('inout-table-component', require('./components/inout/TableComponent.vue').default);
Vue.component('inout-form-component', require('./components/inout/FormComponent.vue').default);
Vue.component('inout-admin-form-component', require('./components/inout/AdminFormComponent.vue').default);
Vue.component('inout-filter-component', require('./components/inout/FilterComponent.vue').default);
Vue.component('inout-total-hours-component', require('./components/inout/TotalHoursComponent.vue').default);

Vue.component('hospitals-table-component', require('./components/hospitals/TableComponent.vue').default);
Vue.component('hospitals-form-component', require('./components/hospitals/FormComponent.vue').default);
Vue.component('hospitals-filter-component', require('./components/hospitals/FilterComponent.vue').default);

Vue.component('portfolio-table-component', require('./components/portfolio/TableComponent.vue').default);
Vue.component('portfolio-form-component', require('./components/portfolio/FormComponent.vue').default);
Vue.component('portfolio-filter-component', require('./components/portfolio/FilterComponent.vue').default);

Vue.component('roles-table-component', require('./components/roles/TableComponent.vue').default);
Vue.component('roles-form-component', require('./components/roles/FormComponent.vue').default);
Vue.component('roles-access-form-component', require('./components/roles/AccessFormComponent.vue').default);
Vue.component('roles-filter-component', require('./components/roles/FilterComponent.vue').default);

Vue.component('users-table-component', require('./components/users/TableComponent.vue').default);
Vue.component('users-form-component', require('./components/users/FormComponent.vue').default);
Vue.component('users-filter-component', require('./components/users/FilterComponent.vue').default);

Vue.component('vehicles-table-component', require('./components/vehicles/TableComponent.vue').default);
Vue.component('vehicles-form-component', require('./components/vehicles/FormComponent.vue').default);
Vue.component('vehicles-filter-component', require('./components/vehicles/FilterComponent.vue').default);

Vue.component('sign-budget-component', require('./components/contracts/SignComponent.vue').default);
/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

import VueSweetalert2 from 'vue-sweetalert2';
import {ServerTable, ClientTable, Event} from 'vue-tables-2';

import vSelect from 'vue-select'
import Datepicker from 'vue2-datepicker';
import VueSignaturePad from 'vue-signature-pad';
import VueToast from 'vue-toast-notification';

import 'sweetalert2/dist/sweetalert2.min.css';
import 'vue-toast-notification/dist/theme-sugar.css';

Vue.use(VueSignaturePad);
const options = {
  confirmButtonColor: '#41b882',
  cancelButtonColor: '#ff7674'
}
 
Vue.use(VueSweetalert2, options)
Vue.use(ServerTable);
Vue.use(ClientTable);
Vue.use(VueToast);
Vue.component('v-select', vSelect) 

const app = new Vue({
    el: '#app',
    mounted(){
        $(document).on('hidden.bs.modal', '.modal',
        () => $('.modal:visible').length && $(document.body).addClass('modal-open'));
    }
});
