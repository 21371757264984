<template>
	<div class="modal fade action-sheet opax" id="commentModal" tabindex="-1" role="dialog">
	    <div class="modal-dialog modal-md" role="document">
	    	<div class="modal-content">
			    <div class="modal-header">
				    <h5 class="modal-title">{{ comment.id?'Editar ':'Nuevo '}} comentario</h5>
				    <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-on:click="cancel();">
				    <span aria-hidden="true">&times;</span>
				    </button>
				</div>
				<div class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            <label>Tipo *</label>
                            <select v-model="comment.type" class="form-control" v-bind:class="{'is-invalid':errors.type}">
                            	<option value="service">Info. servicio</option>
	                            <option value="route">Info. ruta</option>
	                            <option value="contracts">Info. contratación</option>
	                            <option value="incident">Incidencias</option>
                            </select>
                        </div>
                        <div class="col-12">
                            <label>Comentario *</label>
                            <textarea v-model="comment.message" class="form-control" v-bind:class="{'is-invalid':errors.message}"></textarea>
                        </div>
                    </div>
				</div>
				<div class="modal-footer">
				    <button type="button" class="btn btn-success mr-2" v-on:click="save();" v-bind:disabled="saving"><i class="fa fa-spin fa-spinner" v-show="saving"></i> Guardar</button>
				    <button type="button" class="btn btn-danger" data-dismiss="modal" v-on:click="cancel();">Cancelar</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
    export default {
        mounted() {
            this.$root.$on('contract.info.add',this.openModal);
        },
        data(){
            return {
            	base: window.urls['contracts-info.index'],
            	saving: false,
                comment:{
                    type: null,
                    message: null,
                },
                errors: {
                    type: null,
                    message: null,
                },
            }
        },
        methods:{
        	openModal(data){
                if(data){
                    this.comment={
                    	id_contract: data.contract.id,
                        type: null,
                        message: null,
                    };
                    this.resetErrors();
        			$('#commentModal').modal({'backdrop':'static'});
                }
        	},
            resetErrors(){
                this.errors={
                    type: false,
                    message: false,
                };
            },
            validate(){
                var valid = true;
                this.errors.type = this.comment.type == null || this.comment.type == "";
                this.errors.message = this.comment.message == null || this.comment.message == "";
                
                for(var i in this.errors)
                    valid &= !this.errors[i];
                if(!valid)
                    this.showRequiredMessage();
                return valid;
            },
            showRequiredMessage(){
                this.$swal(
                    'Atención',
                    'Hay datos obligatorios por rellenar',
                    'warning'
                );
            },
            save(){
                if(!this.validate())
                    return;
                var url=this.base;
                var vm=this;
                if(this.comment.id) url += '/' + this.comment.id;
                this.saving=true;
                axios({
                    method: 'post',
                    url: url,
                    data: this.comment,
                    responseType: 'json',
                }).then(function(response){
                    vm.saving=false;
                    vm.$root.$emit('contract.info.updated');
                    vm.cancel();
                    vm.$swal(
                      'Correcto',
                      'Se ha guardado el ' + vm.title,
                      'success'
                    );
                })
                .catch(function (error) {
                    vm.saving = false;
                    var message = 'Parece que algo fue mal';
                    if(error.response.status == 400)
                        message = error.response.data.message;
                    if(error.response.status == 404)
                        message = "El registro ya no existe";
                    vm.$swal(
                      '¡Ups!',
                      message,
                      'error'
                    );
                });
            },
            cancel(){
            	$('#commentModal').modal('hide');
            }
        }
    }
</script>
