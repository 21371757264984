<template>
	<div>
		<div>
			<button class="btn btn-info" v-on:click="addClient">Añadir cliente</button>
			<div class="mt-3 table-responsive" v-if="clients.length > 0">
				<table class="table">
					<thead>
						<tr>
							<th>DNI</th>
							<th>Expediente</th>
							<th>Nombre</th>
							<th>Cliente</th>
							<th>Tipo</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(c, index) in clients">
							<td>{{ c.dni }}</td>
							<td>{{ c.type_of_expedient|transformTypeOfExpedient }}</td>
							<td>{{ c.name }} {{ c.surname }}</td>
							<td>{{ c.who_is|transformWhoIs }}</td>
							<td>{{ c.type_of_client|transformClient }}</td>
							<td class="text-right">
								<a href="javascript:void(0)" v-on:click="editClient(c, index)">
									<i class="fas fa-edit"></i>
								</a>
								<a href="javascript:void(0)" class="ml-2" v-on:click="removeClient(c, index)">
									<i class="fas fa-trash"></i>
								</a>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<div class="mt-3" v-if="clients.length > 0">
			<hr class="my-3" />
			<button class="btn btn-info" v-on:click="addContract">Añadir contrato</button>
			<div class="mt-3 table-responsive" v-if="contracts.length > 0">
				<table class="table">
					<thead>
						<tr>
							<th>Nº</th>
							<th>Precio</th>
							<th>IVA</th>
							<th>Descuento</th>
							<th>Total</th>
							<th>Estado</th>
							<th>Fecha</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(c, index) in contracts">
							<td>{{ getBudgetNumber(c) }}</td>
							<td>{{ c.price|round }}</td>
							<td>{{ c.tax|round }}</td>
							<td>{{ c.discount|round }}</td>
							<td>{{ c.total|round }}</td>
							<td>{{ c.status|transformStatus }}</td>
							<td>{{ c.created_at|date }}</td>
							<td class="text-right">
								<a v-if="c.status != 'success'" v-bind:href="'/pay?id=' + c.id + '&amount=' + c.total"  target="_blank">
									<i class="fas fa-credit-card"></i>
								</a>
								<a href="javascript:void(0)" class="ml-2" v-on:click="editContract(c, index)">
									<i class="fas fa-edit"></i>
								</a>
								<a href="javascript:void(0)" class="ml-2" v-on:click="removeContract(c, index)">
									<i class="fas fa-trash"></i>
								</a>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		props: ['contract', 'clients', 'contracts'],
		mounted(){
			this.configureBroadcastMessaging();
			//this.$root.$on('contracts.clients.updated', this.updateClients);
			//this.$root.$on('contracts.budgets.updated', this.updateContracts);
		},
		filters: {
			transformTypeOfExpedient(input){
				switch(input){
					case 'complete': return 'Completo'; break;
					case 'donations': return 'Donaciones, miembros y fetos'; break;
					case 'increase': return 'Mejora'; break;
					case 'discount': return 'Tipo descuento'; break;
				}
			},
			transformClient(input){
				switch(input){
					case 'particular': return 'Particular'; break;
					case 'company': return 'Compañía de seguros'; break;
					case 'enterprise': return 'Empresa'; break;
					case 'third_party': return 'Terceros'; break;
				}
			},
			transformWhoIs(input){
				switch(input){
					case 'solicitant': return 'Solicitante'; break;
					case 'deceased': return 'Fallecido'; break;
					case 'other': return 'Otro'; break;
				}
			},
			transformStatus(input){
				switch(input){
					case 'pending': return 'Pendiente'; break;
					case 'success': return 'Success'; break;
					case 'error': return 'Error'; break;
				}
			},
			round(input){
        		if(input)
        			return parseFloat(input).toFixed(2).replace(".", ",");
        		return "0,00";
        	},
        	date(input){
        		if(input)
        			return moment(input).format('DD/MM/YYYY');
        	}
		},
		data(){
			return {
				base: window.urls['contracts.clients.index'],
				baseContracts: window.urls['contracts.clients.budget.index']
			}
		},
		methods: {
			configureBroadcastMessaging(){
    			var bc = new BroadcastChannel('sign.budget.channel');
            	bc.onmessage = this.contractSigned;
        	},
        	contractSigned(ev){
        		var data = ev.data;
        		var id = parseInt(data.id);
        		for(var i in this.contracts){
        			if(this.contracts[i].id == data.id){
        				this.contracts[i].number = data.number;
        			}
        		}
        	},
			getBudgetNumber(budget){
				if(!this.contract.number)
					return budget.id;
				if(budget.number == null)
					return budget.id;
				if(budget.number == 0)
					return this.contract.number;
				return this.contract.number + '.' + budget.number;
			},
			addClient(){
				this.$root.$emit('contracts.clients.add');
			},
			editClient(row, index){
				this.$root.$emit('contracts.clients.edit', row);
			},
			updateClients(data){
				var index = -1;
				for(var i in this.clients){
					if(this.clients[i].id == data.id){
						this.$set(this.clients, index, data);
						return ;
					}
				}
				if(index == -1)
					this.clients.push(data);
			},
			removeClient(row, index){
	  			var vm = this;
	  			this.$swal({
					title: "Eliminar",
					text: "No podrás deshacer esta acción",
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Sí',
				}).then((result) => {
                    if(result.value){
                    	vm.clients.splice(index, 1);
                        axios({
		                    method: 'delete',
		                    url: this.base  + '/' + row.id,
		                    responseType: 'json',
		                }).then(function(response){

		                })
		                .catch(function (error) {
		                    vm.$swal(
		                      '¡Ups!',
		                      'Parece que algo fue mal',
		                      'error'
		                    );  
		                });
                    }
				})
	  		},
	  		addContract(){
	  			this.$root.$emit('contracts.contracts.add', {
	  				clients: this.clients
	  			});
	  		},
	  		editContract(row, index){
	  			this.$root.$emit('contracts.contracts.edit', {
	  				clients: this.clients,
	  				contract: row
	  			});
	  		},
	  		removeContract(row, index){
	  			var vm = this;
	  			this.$swal({
					title: "Eliminar",
					text: "No podrás deshacer esta acción",
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Sí',
				}).then((result) => {
                    if(result.value){
                    	vm.contracts.splice(index, 1);
                        axios({
		                    method: 'delete',
		                    url: this.baseContracts  + '/' + row.id,
		                    responseType: 'json',
		                }).then(function(response){

		                })
		                .catch(function (error) {
		                    vm.$swal(
		                      '¡Ups!',
		                      'Parece que algo fue mal',
		                      'error'
		                    );  
		                });
                    }
				})
	  		},
	  		updateContracts(data){
				var index = -1;
				for(var i in this.contracts){
					if(this.contracts[i].id == data.id){
						this.$set(this.contracts, i, data);
						return ;
					}
				}
				if(index == -1)
					this.contracts.push(data);
	  		}
		}
	}
</script>