<template>
	<datepicker v-model="componentDate" style="display: block;width: 100%"  v-bind:lang="lang" format="HH:mm" type="time" v-on:change="changeDate" :placeholder="placeholder" input-class="form-control" v-bind:input-class="inputClass"></datepicker>
</template>
<script>
	import Datepicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import 'vue2-datepicker/locale/es';
	export default{
		components: { Datepicker },
		props: {
		    value: {
				required: true
		    },
		    placeholder: null,
		    inputClass: null
		},
		data(){
			return {
				componentDate: null,
				lang: {
					formatLocale: {
	          			firstDayOfWeek: 1,
	              	},
	              	monthBeforeYear: false,
	          	},
          };
		},
		mounted(){
			if(this.value != null)
				this.componentDate = new Date("1970-01-01 " + this.value)
		},
		methods: {
			changeDate(evt){
				var value = null
				if(evt!=null) value = moment(evt).format('HH:mm');
            	else value = null;
            	this.$emit('input', value);
			},
		}
	}
</script>