<template>
	<div class="modal fade action-sheet opax" id="rolesModal" tabindex="-1" role="dialog">
	    <div class="modal-dialog modal-md" role="document">
	    	<div class="modal-content">
			    <div class="modal-header">
				    <h5 class="modal-title">{{ roles.id?'Editar perfil':'Nuevo perfil'}}</h5>
				    <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-on:click="cancel();">
				    <span aria-hidden="true">&times;</span>
				    </button>
				</div>
				<div class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            <label>Nombre *</label>
                            <input spellcheck="true" type="text" class="form-control" v-model="roles.role" v-on:blur="firstLetterOnUpperCase('role')" placeholder="Nombre" v-bind:class="{'is-invalid':errors.role}"/>
                        </div>
                    </div>
				</div>
				<div class="modal-footer">
				    <button type="button" class="btn btn-success mr-2" v-on:click="save();" v-bind:disabled="saving"><i class="fa fa-spin fa-spinner" v-show="saving"></i> Guardar</button>
				    <button type="button" class="btn btn-danger" data-dismiss="modal" v-on:click="cancel();">Cancelar</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
    export default {
        mounted() {
            this.$root.$on('roles.add',this.openModal);
            this.$root.$on('roles.edit',this.openModal);
        },
        data(){
            return {
            	base: window.urls['roles.index'],
                saving: false,
                roles:{
                    role: null,
                },
                errors: {
                    role: false,
                },
            }
        },
        methods:{
        	openModal(data){
                if(data){
                    this.roles={
                        id: data.id,
                        role: data.role,
                    };
                }else{
                    this.roles={
                        role: null,
                    };
                }
                this.resetErrors();
        		$('#rolesModal').modal({'backdrop':'static'});
        	},
            resetErrors(){
                this.errors={
                    role: false,
                };
            },
            firstLetterOnUpperCase(field){
                var aux=this.roles[field].split(" ");
                var final="";
                for(var i=0;i<aux.length;i++){
                    final+=aux[i].charAt(0).toUpperCase() + aux[i].slice(1);
                    if(i<aux.length-1) final+=" ";
                }
                this.roles[field]=final;
            },
            validate(){
                var valid = true;
                this.errors.role = this.roles.role == null || this.roles.role == "";
                for(var i in this.errors)
                    valid &= !this.errors[i];
                if(!valid)
                    this.showRequiredMessage();
                return valid;
            },
            showRequiredMessage(){
                this.$swal(
                    'Atención',
                    'Hay datos obligatorios por rellenar',
                    'warning'
                );
            },
            save(){
                if(!this.validate())
                    return;
                var url=this.base;
                var vm=this;
                if(this.roles.id) url += '/' + this.roles.id;
                this.saving=true;
                axios({
                    method: 'post',
                    url: url,
                    data: this.roles,
                    responseType: 'json',
                }).then(function(response){
                    vm.saving=false;
                    vm.$root.$emit('roles.updated');
                    vm.cancel();
                    vm.$swal(
                      'Correcto',
                      'Se ha guardado el comercial',
                      'success'
                    );
                })
                .catch(function (error) {
                    vm.saving = false;
                    var message = 'Parece que algo fue mal';
                    if(error.response.status == 400)
                        message = error.response.data.message;
                    if(error.response.status == 404)
                        message = "El registro ya no existe";
                    vm.$swal(
                      '¡Ups!',
                      message,
                      'error'
                    );
                });
            },
            cancel(){
            	$('#rolesModal').modal('hide');
            }
        }
    }
</script>
