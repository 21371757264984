<template>
	<div class="modal fade action-sheet opax" id="contractModal" tabindex="-1" role="dialog">
	    <div class="modal-dialog modal-fullscreen" role="document">
	    	<div class="modal-content">
			    <div class="modal-header">
				    <h5 class="modal-title">{{ contract.id?'Editar expediente':'Nuevo expediente'}}</h5>
				    <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-on:click="cancel();">
				    <span aria-hidden="true">&times;</span>
				    </button>
				</div>
				<div class="modal-body" v-if="!loading && modalOpened">
                    <ul class="nav nav-tabs mb-3">
                        <li class="nav-item">
                            <a class="nav-link" href="javascript:void(0);" v-bind:class="{'active':section == 'deceased'}" v-on:click="setSection('deceased');">
                                Fallecido
                                <span v-if="errors.deceased > 0" class="badge badge-danger">{{ errors.deceased }}</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="javascript:void(0);" v-bind:class="{'active':section == 'deceasing'}" v-on:click="setSection('deceasing');">
                                Fallecimiento
                                <span v-if="errors.deceasing > 0" class="badge badge-danger">{{ errors.deceasing }}</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="javascript:void(0);" v-bind:class="{'active':section == 'client'}" v-on:click="setSection('client');">
                                Solicitante 
                                <span v-if="errors.client > 0" class="badge badge-danger">{{ errors.client }}</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="javascript:void(0);" v-bind:class="{'active':section == 'service'}" v-on:click="setSection('service');">
                                Servicio
                                <span v-if="errors.service > 0" class="badge badge-danger">{{ errors.service }}</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="javascript:void(0);" v-bind:class="{'active':section == 'mounting'}" v-on:click="setSection('mounting');">
                                Montaje
                                <span v-if="errors.mounting > 0" class="badge badge-danger">{{ errors.mounting }}</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="javascript:void(0);" v-bind:class="{'active':section == 'destination'}" v-on:click="setSection('destination');">
                                Destino
                                <span v-if="errors.destination > 0" class="badge badge-danger">{{ errors.destination }}</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="javascript:void(0);" v-bind:class="{'active':section == 'documents'}" v-on:click="setSection('documents');">
                                Documentos
                                <span v-if="errors.documents > 0" class="badge badge-danger">{{ errors.documents }}</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="javascript:void(0);" v-bind:class="{'active':section == 'contracts'}" v-on:click="setSection('contracts');">
                                Contratos
                                <span v-if="errors.contracts > 0" class="badge badge-danger">{{ errors.contracts }}</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="javascript:void(0);" v-bind:class="{'active':section == 'info'}" v-on:click="setSection('info');">
                                Info
                                <span v-if="errors.info > 0" class="badge badge-danger">{{ errors.info }}</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="javascript:void(0);" v-bind:class="{'active':section == 'documentation'}" v-on:click="setSection('documentation');">
                                Documentación
                                <span v-if="errors.documentation > 0" class="badge badge-danger">{{ errors.documentation }}</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="javascript:void(0);" v-bind:class="{'active':section == 'status'}" v-on:click="setSection('status');">
                                Estado
                            </a>
                        </li>
                    </ul>
                    <contract-form-client-component v-show="section == 'client'"  v-bind:countries="countries"></contract-form-client-component>
                    <contract-form-deceased-component v-show="section == 'deceased'" v-bind:countries="countries"></contract-form-deceased-component>
                    <contract-form-deceasing-component v-show="section == 'deceasing'" v-bind:contract="contract"  v-bind:hospitals="hospitals"></contract-form-deceasing-component>
                    <contract-form-service-component v-show="section == 'service'" v-bind:commercials="commercials" v-bind:coffinings="coffinings" v-bind:companies="companies" v-bind:ornaments="ornaments" v-bind:reminders="reminders" v-bind:floral="floralOrnaments" v-bind:providers="providers" v-bind:contract="contract"></contract-form-service-component>
                    <contract-form-mounting-component v-show="section == 'mounting'" v-bind:contract="contract" v-bind:arks="arks" v-bind:vehicles="vehicles"></contract-form-mounting-component>
                    <contract-form-destination-component v-show="section == 'destination'" v-bind:contract="contract" v-bind:states="states" v-bind:countries="countries" v-bind:cementeries="cementeries"></contract-form-destination-component>
                    <contract-form-documents-component v-show="section == 'documents'" v-bind:contract="contract" v-bind:documents="documents"></contract-form-documents-component>
                    <contract-form-contracts-component v-show="section == 'contracts'" v-bind:contract="contract" v-bind:clients="clients" v-bind:contracts="contracts"></contract-form-contracts-component>
                    <contract-form-info-component v-if="section == 'info'" v-bind:contract="contract"></contract-form-info-component>
                    <contract-form-documentation-component v-show="section == 'documentation'" v-bind:contract="contract" v-bind:solicitant="solicitant" v-bind:documentation="documentation"></contract-form-documentation-component>
                    <contract-form-status-component v-show="section == 'status'" v-bind:contract="contract"></contract-form-status-component>
				</div>
				<div class="modal-footer">
                    <button v-if="section == 'documents'" type="button" class="btn btn-success mr-2" v-on:click="saveDocuments();" v-bind:disabled="savingDocs"><i class="fas fa-spin fa-spinner" v-show="savingDocs"></i> Guardar documentación</button>
                    <button v-if="section != 'deceased'" type="button" class="btn btn-info mr-2" v-on:click="backStep();">Anterior</button>
				    <button v-if="section != 'documentation'" type="button" class="btn btn-info mr-2" v-on:click="nextStep();">Siguiente</button>
				    <button type="button" class="btn btn-danger" data-dismiss="modal" v-on:click="cancel();">Cancelar</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
    export default {
        mounted() {
            this.$root.$on('contracts.edit',this.openModal);
            this.$root.$on('contracts.section.validated',this.validateSection);
            this.$root.$on('contracts.section.set.client',this.setClient);
            this.$root.$on('contracts.section.set.deceased',this.setDeceased);
            this.$root.$on('contracts.section.set.documents',this.setDocuments);
            this.$root.$on('hospitals.updated', this.addNewHospital);
            this.$root.$on('arks.updated', this.addNewArk);
            this.$root.$on('coffining.updated', this.addNewCoffing);
            this.$root.$on('contracts.clients.updated', this.updateClients);
            this.$root.$on('contracts.budgets.updated', this.updateContracts);
            this.getContext();
            var vm = this;
            $('#contractModal').on('hidden.bs.modal', function (e) {
                 vm.modalOpened = false;
            })
        },
        watch: {
            contract:{
                deep: true,
                handler(){
                    if(this.opening)
                        return ;

                    var vm = this;
                    for(var i in this.contract){
                        if(this.contract[i] != this.initialContract[i]){
                            var field = i;
                            this.initialContract[field] = this.contract[field];
                            this.saveQueue[field] = this.contract[field];
                            if(this.savesTimeout)
                                clearTimeout(this.savesTimeout);
                            this.savesTimeout = setTimeout(function(){
                                vm.saveFieldExpedient();
                            }, 1000);
                            
                        }
                    }
                }
            },
        },
        data(){
            return {
            	base: window.urls['contracts.index'],
                savesTimeout: null,
                saveQueue: {},
                section: 'deceased',
                opening: true,
                loading: true,
                saving: false,
                savingDocs: false,
                solicitant: null,
                deceased: null,
                client: null,
                modalOpened: false,
                floralOrnaments: [],
                providers: [],
                reminders: [],
                sections: [
                    'deceased',
                    'deceasing',
                    'client',
                    'service',
                    'mounting',
                    'destination',
                    'documents',
                    'contracts',
                    'info',
                    'documentation',
                    'status'
                ],
                documents:{
                    deceased_front: null,
                    deceased_back: null,
                    solicitant_front: null,
                    solicitant_back: null,
                    authorization: null,
                    bank_receipt: null,
                    medical_certification: null,
                    title: null,
                    license_frontal: null,
                    license_back: null,
                },
                clients: [],
                documentation: [],
                countries: [],
                coffinings: [],
                hospitals: [],
                commercials: [],
                companies: [],
                states: [],
                installations: [],
                arks: [],
                vehicles: [],
                cementeries: [],
                bank_accounts: [],
                contracts: [],
                ornaments: [],
                contract: {
                    
                },
                initialContract: {},
                errors: {
                    client: 0,
                    deceased: 0,
                    deceasing: 0,
                    service: 0,
                    mounting: 0,
                    destination: 0,
                    documents: 0,
                    contracts: 0,
                    info: 0,
                    documentation: 0
                },
            }
        },
        methods:{
            setSection(newSection){
                var prevSection = this.section;
                this.section = newSection;
                this.$root.$emit('contracts.section.changed.' + prevSection);
                this.$root.$emit('contracts.section.changed.new.' + newSection);
            },
            getContext(){
                var vm = this;
                axios({
                    method: 'get',
                    url: this.base + '/context',
                    responseType: 'json',
                }).then(function(response){
                    vm.$root.$emit('contracts.context', response.data);
                    vm.countries = response.data.countries;
                    vm.hospitals = response.data.hospitals;
                    vm.commercials = response.data.commercials;
                    vm.states = response.data.states;
                    vm.installations = response.data.installations;
                    vm.arks = response.data.arks;
                    vm.vehicles = response.data.vehicles;
                    vm.cementeries = response.data.cementeries;
                    vm.bank_accounts = response.data.bank_accounts;
                    vm.companies = response.data.companies;
                    vm.ornaments = response.data.ornaments;
                    vm.coffinings = response.data.coffinings;
                    vm.providers = response.data.providers;
                    Vue.nextTick(function(){
                        vm.loading = false;
                    });
                })
                .catch(function (error) {
                    console.log(error)
                    vm.saving = false;
                    var message = 'No se pudo cargar la información del servidor';
                    vm.$swal(
                        '¡Ups!',
                        message,
                        'error'
                    );
                });
            },
        	openModal(data){
                if(this.loading)
                    return ;
                var vm = this;
                this.opening = true;
                this.section = 'deceased';
                Vue.nextTick(function(){
                    vm.endOpenModal(data)
                });
        	},
            endOpenModal(data){
                var vm = this;
                if(data){
                    this.contract={
                        id: data.id,
                        status: data.status,
                        id_deceased: data.id_deceased,
                        id_solicitant: data.id_solicitant,
                        id_hospital: data.id_hospital,
                        id_commercial: data.id_commercial,
                        id_company: data.id_company,
                        id_ornament: data.id_ornament,
                        delegation: data.delegation,
                        state: data.state,
                        area: data.area,
                        zone: data.zone,
                        type_of_folder: data.type_of_folder,
                        type_of_expedient: data.type_of_expedient,
                        relation: data.relation,
                        deceasing_place: data.deceasing_place,
                        other_deceasing_place: data.other_deceasing_place,
                        deceasing_state: data.deceasing_state,
                        deceasing_area: data.deceasing_area,
                        deceasing_zone: data.deceasing_zone,
                        deceasing_date: data.deceasing_date,
                        deceasing_time: data.deceasing_time,
                        deceasing_inmediate_cause: data.deceasing_inmediate_cause,
                        deceasing_intermediate_cause: data.deceasing_intermediate_cause,
                        deceasing_fundamental_cause: data.deceasing_fundamental_cause,
                        deceasing_other_processes: data.deceasing_other_processes,
                        number_certificate: data.number_certificate,
                        previous_diligences: data.previous_diligences,
                        judge_number: data.judge_number,
                        college_number: data.college_number,
                        college_state: data.college_state,
                        college_name: data.college_name,
                        service_carpet_type: data.service_carpet_type,
                        service_type_of_client: data.service_type_of_client,
                        service_type: data.service_type,
                        service_delivery_company: data.service_delivery_company,
                        other_service_delivery_company: data.other_service_delivery_company,
                        service_sickness: data.service_sickness,
                        service_id_coffining: data.service_id_coffining,
                        service_destination_tanatory: data.service_destination_tanatory,
                        service_id_oportunity: data.service_id_oportunity,
                        service_intermediate_instalation: data.service_intermediate_instalation,
                        service_intermediate_instalation_location: data.service_intermediate_instalation_location,
                        service_estimated: data.service_estimated,
                        service_chamber_estimated: data.service_chamber_estimated,
                        service_chamber_date_entrance_estimated: data.service_chamber_date_entrance_estimated,
                        service_chamber_time_entrance_estimated: data.service_chamber_time_entrance_estimated,
                        service_chamber_date_exit_estimated: data.service_chamber_date_exit_estimated,
                        service_chamber_time_exit_estimated: data.service_chamber_time_exit_estimated,
                        ornament_color: data.ornament_color,
                        ornament_observations: data.ornament_observations,
                        destination_type: data.destination_type,
                        destination_state: data.destination_state,
                        destination_place: data.destination_place,
                        destination_nw: data.destination_nw,
                        destination_city: data.destination_city,
                        destination_country: data.destination_country,
                        destination_date_entrance: data.destination_date_entrance,
                        destination_time_entrance: data.destination_time_entrance,
                        destination_date_exit: data.destination_date_exit,
                        destination_time_exit: data.destination_time_exit,
                        destination_reception_funeral: data.destination_reception_funeral,
                        destination_wait_in_funeral: data.destination_wait_in_funeral,
                        destination_reception_observations: data.destination_reception_observations,
                        inhumation_title: data.inhumation_title,
                        inhumation_delegates_signed: data.inhumation_delegates_signed,
                        inhumation_unit_type_cemetery: data.inhumation_unit_type_cemetery,
                        inhumation_cemetery_property: data.inhumation_cemetery_property,
                        inhumation_last_inhumated: data.inhumation_last_inhumated,
                        inhumation_reduction: data.inhumation_reduction,
                        inhumation_second_option: data.inhumation_second_option,
                        incineration_ash_pickup: data.incineration_ash_pickup,
                        incineration_urn_model: data.incineration_urn_model,
                        incineration_ash_destination: data.incineration_ash_destination,
                        incineration_flower_ornaments: data.incineration_flower_ornaments,
                        incineration_pacemaker: data.incineration_pacemaker,
                        incineration_extraction: data.incineration_extraction,
                        incineration_delivery: data.incineration_delivery,
                        incineration_urn_registration: data.incineration_urn_registration,
                        incineration_urn_registration_date: data.incineration_urn_registration_date,
                        incineration_informed_family: data.incineration_informed_family,
                        incineration_user_comunicate: data.incineration_user_comunicate,
                        incineration_comunication_date: data.incineration_comunication_date,
                        ash_delivery_date: data.ash_delivery_date,
                        ash_delivery_time: data.ash_delivery_time,
                        ash_delivery_place: data.ash_delivery_place,
                        ash_burry_date: data.ash_burry_date,
                        ash_burry_time: data.ash_burry_time,
                        ash_burry_place: data.ash_burry_place,
                        service_inventorum: data.service_inventorum,
                        service_inventorum_request_date: data.service_inventorum_request_date,
                        service_inventorum_delivered_date: data.service_inventorum_delivered_date,
                        service_inventorum_sent: data.service_inventorum_sent,
                        service_inventorum_sent_date: data.service_inventorum_sent_date,
                        service_inventorum_delivery_address: data.service_inventorum_delivery_address,
                        service_inventorum_delivery_email: data.service_inventorum_delivery_email,
                        mounting_zinc: data.mounting_zinc,
                        mounting_cross: data.mounting_cross,
                        mounting_crist: data.mounting_crist,
                        mounting_room_reasons: data.mounting_room_reasons,
                        mounting_funeral_mass: data.mounting_funeral_mass,
                        mounting_funeral_response: data.mounting_funeral_response,
                        mounting_crematory_response: data.mounting_crematory_response,
                        mounting_music: data.mounting_music,
                        mounting_remember: data.mounting_remember,
                        mounting_obituary_press: data.mounting_obituary_press,
                        mounting_obituary_portal: data.mounting_obituary_portal,
                        mounting_dress: data.mounting_dress,
                        mounting_shave: data.mounting_shave,
                        mounting_makeup: data.mounting_makeup,
                        mounting_conditioning: data.mounting_conditioning,
                        mounting_signs_book: data.mounting_signs_book,
                        mounting_elevator: data.mounting_elevator,
                        mounting_gestory: data.mounting_gestory,
                        mounting_catering: data.mounting_catering,
                        mounting_mass_date: data.mounting_mass_date,
                        mounting_mass_hour: data.mounting_mass_hour,
                        mounting_mass_place: data.mounting_mass_place,
                        mounting_parish_place: data.mounting_parish_place,
                        mounting_payment_priest: data.mounting_payment_priest,
                        number: data.number,
                        clothes_in: data.clothes_in,
                        coffin: data.coffin,
                        coffin_arc: data.coffin_arc,
                        coffin_arc_code: data.coffin_arc_code,
                        coffin_outcoming_amount: data.coffin_outcoming_amount,
                        coffin_orn_code: data.coffin_orn_code,
                        coffin_zinc_bag: data.coffin_zinc_bag,
                        coffin_bag_code: data.coffin_bag_code,
                        vehicle_driver: data.vehicle_driver,
                        vehicle_vehicle: data.vehicle_vehicle,
                        vehicle_enrollment: data.vehicle_enrollment,
                        vehicle_kilometers: data.vehicle_kilometers,
                        vehicle_duels: data.vehicle_duels,
                        vehicle_duels_origin: data.vehicle_duels_origin,
                        vehicle_duels_returning: data.vehicle_duels_returning,
                        vehicle_mounter_asigned: data.vehicle_mounter_asigned,
                        thanatopraxy_date: data.thanatopraxy_date,
                        thanatopraxy_time: data.thanatopraxy_time,
                        thanatopraxy_place: data.thanatopraxy_place,
                        thanatopraxy_technique: data.thanatopraxy_technique,
                        thanatopraxy_technical: data.thanatopraxy_technical,
                        thanatopraxy_company: data.thanatopraxy_company,
                        thanatopraxy_weight: data.thanatopraxy_weight,
                        thanatopraxy_height: data.thanatopraxy_height,
                        thanatopraxy_origin: data.thanatopraxy_origin,
                        thanatopraxy_origin_type: data.thanatopraxy_origin_type,
                        thanatopraxy_observations: data.thanatopraxy_observations,
                        company_vehicle_duels: data.company_vehicle_duels,
                        requested_vehicle_duels: data.requested_vehicle_duels,
                        start_vehicle_duels: data.start_vehicle_duels
                    };
                    this.deceased = data.deceased;
                    this.solicitant = data.solicitant;
                    this.documentation = data.documentation;
                    this.clients = data.clients;
                    this.contracts = data.contracts;
                    this.floralOrnaments = data.floral_ornaments;
                    this.reminders = data.reminders;
                }else{
                    this.contract={
                        status: 'assigned',
                        id_deceased: null,
                        id_solicitant: null,
                        id_hospital: null,
                        id_commercial: null,
                        id_company: null,
                        id_ornament: null,
                        delegation: null,
                        state: null,
                        area: null,
                        zone: null,
                        type_of_folder: null,
                        type_of_expedient: null,
                        relation: null,
                        deceasing_place: null,
                        other_deceasing_place: null,
                        deceasing_state: null,
                        deceasing_area: null,
                        deceasing_zone: null,
                        deceasing_date: null,
                        deceasing_time: null,
                        deceasing_inmediate_cause: null,
                        deceasing_intermediate_cause: null,
                        deceasing_fundamental_cause: null,
                        deceasing_other_processes: null,
                        number_certificate: null,
                        college_number: null,
                        previous_diligences: null,
                        judge_number: null,
                        college_state: null,
                        college_name: null,
                        service_carpet_type: null,
                        service_type_of_client: null,
                        service_type: null,
                        service_delivery_company: null,
                        other_service_delivery_company: null,
                        service_sickness: null,
                        service_id_coffining: null,
                        service_destination_tanatory: null,
                        service_id_oportunity: null,
                        service_intermediate_instalation: null,
                        service_intermediate_instalation_location: null,
                        service_estimated: null,
                        service_chamber_estimated: null,
                        service_chamber_date_entrance_estimated: null,
                        service_chamber_time_entrance_estimated: null,
                        service_chamber_date_exit_estimated: null,
                        service_chamber_time_exit_estimated: null,
                        ornament_color: null,
                        ornament_observations: null,
                        destination_type: null,
                        destination_state: null,
                        destination_place: null,
                        destination_nw: null,
                        destination_city: null,
                        destination_country: null,
                        destination_date_entrance: null,
                        destination_time_entrance: null,
                        destination_date_exit: null,
                        destination_time_exit: null,
                        destination_reception_funeral: null,
                        destination_wait_in_funeral: null,
                        destination_reception_observations: null,
                        inhumation_title: null,
                        inhumation_delegates_signed: null,
                        inhumation_unit_type_cemetery: null,
                        inhumation_cemetery_property: null,
                        inhumation_last_inhumated: null,
                        inhumation_reduction: null,
                        inhumation_second_option: null,
                        incineration_ash_pickup: null,
                        incineration_urn_model: null,
                        incineration_ash_destination: null,
                        incineration_flower_ornaments: null,
                        incineration_pacemaker: null,
                        incineration_extraction: null,
                        incineration_delivery: null,
                        incineration_urn_registration: null,
                        incineration_urn_registration_date: null,
                        incineration_user_comunicate: null,
                        incineration_comunication_date: null,
                        ash_delivery_date: null,
                        ash_delivery_time: null,
                        ash_delivery_place: null,
                        ash_burry_date: null,
                        ash_burry_time: null,
                        ash_burry_place: null,
                        service_inventorum: '0',
                        service_inventorum_request_date: null,
                        service_inventorum_delivered_date: null,
                        service_inventorum_sent: '0',
                        service_inventorum_sent_date: null,
                        service_inventorum_delivery_address: null,
                        service_inventorum_delivery_email: null,
                        mounting_zinc: false,
                        mounting_cross: false,
                        mounting_crist: false,
                        mounting_room_reasons: false,
                        mounting_funeral_mass: false,
                        mounting_funeral_response: false,
                        mounting_crematory_response: false,
                        mounting_music: false,
                        mounting_remember: false,
                        mounting_obituary_press: false,
                        mounting_obituary_portal: false,
                        mounting_dress: false,
                        mounting_shave: false,
                        mounting_makeup: false,
                        mounting_conditioning: false,
                        mounting_signs_book: false,
                        mounting_elevator: false,
                        mounting_gestory: null,
                        mounting_catering: null,
                        mounting_mass_date: null,
                        mounting_mass_hour: null,
                        mounting_mass_place: null,
                        mounting_parish_place: null,
                        mounting_payment_priest: null,
                        number: null,
                        clothes_in: null,
                        coffin: null,
                        coffin_arc: null,
                        coffin_arc_code: null,
                        coffin_outcoming_amount: null,
                        coffin_orn_code: null,
                        coffin_zinc_bag: null,
                        coffin_bag_code: null,
                        vehicle_driver: null,
                        vehicle_vehicle: null,
                        vehicle_enrollment: null,
                        vehicle_kilometers: null,
                        vehicle_duels: null,
                        vehicle_duels_origin: null,
                        vehicle_duels_returning: null,
                        vehicle_mounter_asigned: null,
                        thanatopraxy_date: null,
                        thanatopraxy_time: null,
                        thanatopraxy_place: null,
                        thanatopraxy_technique: null,
                        thanatopraxy_technical: null,
                        thanatopraxy_company: null,
                        thanatopraxy_weight: null,
                        thanatopraxy_height: null,
                        thanatopraxy_origin: null,
                        thanatopraxy_origin_type: null,
                        thanatopraxy_observations: null,
                        company_vehicle_duels: null,
                        requested_vehicle_duels: null,
                        start_vehicle_duels: null
                    };
                    this.deceased = null;
                    this.solicitant = null;
                    this.documentation = null;
                    this.clients = [];
                    this.floralOrnaments = [];
                    this.reminders = [];
                }
                this.resetErrors();
                this.initialContract = JSON.parse(JSON.stringify(this.contract));
                Vue.nextTick(function(){
                    vm.opening = false;
                    vm.modalOpened = true;
                    Vue.nextTick(function(){
                        vm.$root.$emit('form.initialize.deceased', data);
                        vm.$root.$emit('form.initialize.client', data);
                        vm.$root.$emit('form.initialize.documents', data);
                    })
                })
                $('#contractModal').modal({'backdrop':'static'});
            },
            resetErrors(){
                this.errors={
                    client: 0,
                    deceased: 0,
                    deceasing: 0,
                    service: 0,
                    mounting: 0,
                    destination: 0,
                    documents: 0,
                    contracts: 0,
                    info: 0,
                    documentation: 0,
                };
            },
            validateSection(data){
                var section = data.section;
                var errors = data.errors;
                this.errors[section] = errors;
                if(section == 'client' && errors > 0)
                    this.client = null;
                if(section == 'deceased' && errors > 0)
                    this.deceased = null;
            },
            setClient(data){
                this.client = data;
                this.save();
            },
            setDeceased(data){
                this.deceased = data;
                this.save();
            },
            setDocuments(data){
                this.documents = data;
            },
            updateClients(data){
                var index = -1;
                for(var i in this.clients){
                    if(this.clients[i].id == data.id){
                        this.$set(this.clients, index, data);
                        return ;
                    }
                }
                if(index == -1)
                    this.clients.push(data);
            },
            updateContracts(data){
                var index = -1;
                for(var i in this.contracts){
                    if(this.contracts[i].id == data.id){
                        this.$set(this.contracts, index, data);
                        return ;
                    }
                }
                if(index == -1)
                    this.contracts.push(data);
            },
            validate(){
                var filledData = (this.client != null && this.deceased != null);
                var valid = true;
                var validDates = this.validDates();
                if(!filledData){
                    this.$swal(
                        'Atención',
                        'Debes rellenar los datos mínimos obligatorios (solicitante y fallecido) correctamente para poder guardar el contrato',
                        'warning'
                    );
                }
                if(!validDates){
                    this.$swal(
                        'Atención',
                        'La fecha de fallecimiento no puede ser anterior a la fecha de nacimiento',
                        'warning'
                    );
                }
                valid = filledData && validDates;
                return valid;
            },
            validDates(){
                var birthDate = this.deceased && this.deceased.birthdate?moment(this.deceased.birthdate).unix():null;
                var deceasingDate = this.contract.deceasing_date?moment(this.contract.deceasing_date).unix():null;
                if(birthDate == null || deceasingDate == null)
                    return true;
                return birthDate <= deceasingDate;
            },
            saveFieldExpedient(){
                var vm = this;
                var url = this.base + '/' + this.contract.id;
                var auxiliar = {};
                for(var i in this.saveQueue)
                    auxiliar[i] = this.saveQueue[i];
                this.saving = true;
                axios({
                    method: 'post',
                    url: url,
                    data: {
                        contract: auxiliar,
                    },
                    responseType: 'json',
                }).then(function(response){
                    vm.saving = false;
                    vm.saveQueue = {};
                    vm.$root.$emit('contracts.updated');
                    Vue.$toast.success('Guardado correctamente');
                })
                .catch(function (error) {
                    vm.saving = false;
                    var message = 'Parece que algo fue mal';
                    if(error.response.status == 400)
                        message = error.response.data.message;
                    if(error.response.status == 404)
                        message = "El registro ya no existe";
                    vm.$swal(
                      '¡Ups!',
                      message,
                      'error'
                    );
                });
            },
            save(){
                if(!this.validate())
                    return;
                var url=this.base;
                var vm=this;
                if(this.contract.id) url += '/' + this.contract.id;
                this.saving=true;
                axios({
                    method: 'post',
                    url: url,
                    data: {
                        contract: this.contract,
                        client: this.client,
                        deceased: this.deceased
                    },
                    responseType: 'json',
                }).then(function(response){
                    vm.saving=false;
                    vm.$root.$emit('contracts.updated');
                })
                .catch(function (error) {
                    vm.saving = false;
                    var message = 'Parece que algo fue mal';
                    if(error.response.status == 400)
                        message = error.response.data.message;
                    if(error.response.status == 404)
                        message = "El registro ya no existe";
                    vm.$swal(
                      '¡Ups!',
                      message,
                      'error'
                    );
                });
            },
            validateDocuments(){
                var documents = 0;
                for(var i in this.documents){
                    if(this.documents[i] != null)
                        documents++;
                }
                return documents > 0;
            },
            backStep(){
                var index = this.sections.indexOf(this.section);
                if(index > 0)
                    this.setSection(this.sections[index - 1]);
            },
            nextStep(){
                var index = this.sections.indexOf(this.section);
                if(index < this.sections.length - 1)
                    this.setSection(this.sections[index + 1]);
            },
            saveDocuments(){
                if(!this.validateDocuments())
                    return;
                var url = this.base;
                var vm = this;
                var data = new FormData();
                if(this.contract.id) url += '/documents/' + this.contract.id;
                this.saving = true;
                this.savingDocs = true;
                for(var i in this.documents){
                    if(this.documents[i] != null)
                        data.append(i, this.documents[i]);
                }

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    responseType: 'json',
                }).then(function(response){
                    vm.savingDocs = false;
                    vm.$root.$emit('contracts.updated');
                    vm.$root.$emit('form.update.documents', response.data);
                    vm.$swal(
                      'Correcto',
                      'Se han guardado los cambios',
                      'success'
                    );
                })
                .catch(function (error) {
                    vm.saving = false;
                    vm.savingDocs = false;
                    var message = 'Parece que algo fue mal';
                    if(error.response.status == 400)
                        message = error.response.data.message;
                    if(error.response.status == 404)
                        message = "El registro ya no existe";
                    vm.$swal(
                        '¡Ups!',
                        message,
                        'error'
                    );
                });
            },
            addNewHospital(hospital){
                this.hospitals.push(hospital);
            },
            addNewArk(ark){
                this.arks.push(ark);
            },
            addNewCoffing(coffing){
                this.coffinings.push(coffing);
            },
            cancel(){
            	$('#contractModal').modal('hide');
            }
        }
    }
</script>
