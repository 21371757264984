<template>
	<v-select v-on:search="search" v-bind:options="localOptions" v-model="option" v-on:input="select" :filter="customSearch" v-bind:placeholder="placeholder" v-bind:disabled="disabled">
        <template slot="spinner">
            <div class="spinner" v-show="spinner"><i class="fa fa-spinner fa-spin"></i></div>
        </template>
        <template #no-options="{ search, searching, loading }">
      		Ninguna coincidencia
    	</template>
    </v-select>
</template>
<script>
	export default{
		props: {
		    value: {
				required: true
		    },
		    placeholder: null,
		    keyCode: null,
		    keyLabel: null,
		    disabled: null,
		    debug: false,
		    options: [],
		},
		data(){
			return {
				localOptions: [],
				option: null,
		    	spinner: false,
		    	blockChange: false,
          	};
		},
		watch: {
			options(){
				this.initializeComponent();
			},
			value(){
				if(this.blockChange)
					return

				var vm = this;
				Vue.nextTick(function(){
					vm.preSelected();
				});
				
			}
		},
		mounted(){
			this.initializeComponent();
		},
		methods: {
			initializeComponent(){
				this.localOptions = [];
				for(var i in this.options){
					this.localOptions.push({
						code: this.options[i][this.keyCode],
						label: this.options[i][this.keyLabel],
					});
				}
				this.preSelected();
				if(this.debug)
					console.log(this.localOptions);
			},
			customSearch(options, search) {
              	return options;
            },
            select(value){
            	var vm = this;
            	this.blockChange = true;
            	if(value)
            		this.$emit('input', value.code);
            	else
            		this.$emit('input', null);
            	this.$emit('change');
            	Vue.nextTick(function(){
            		vm.blockChange = false;
            	});
            },
            search(search){
            	this.localOptions = [];
            	var searchToLower = search.toLowerCase();
            	for(var i in this.options){
            		var label = this.options[i][this.keyLabel].toLowerCase();
            		if(label.indexOf(searchToLower) != -1){
            			this.localOptions.push({
							code: this.options[i][this.keyCode],
							label: this.options[i][this.keyLabel],
						});
            		}
            	}
            },
            preSelected(){
            	for(var i in this.localOptions){
            		if(this.debug){
            			console.log("value");
            			console.log(this.value);
            			console.log("option");
            			console.log(this.localOptions[i].code);
        				
        			}
            		if(this.localOptions[i].code == this.value){

            			this.option = this.localOptions[i];
            			return ;
            		}
            	}
            }
		}
	}
</script>