<template>
	<div>
	    <div class="row">
	        <div class="col-12 col-md">
	            <label>Tipo de destino</label>
	            <select class="form-control" v-model="contract.destination_type" v-bind:class="{'is-invalid':errors.service_type_of_client}">
	            	<option value="">Por definir</option>
	            	<option value="inhumation">Inhumación</option>
	            	<option value="cremation">Cremación</option>
	            	<option value="donation">Donación</option>
	            	<option value="other">Otros</option>
	            </select>
	        </div>
	        <div class="col-12 col-md">
	            <label>Provincia traslado</label>
	            <autocomplete-component v-model="contract.destination_state" v-bind:options="states" v-bind:key-code="'id'" v-bind:key-label="'name'" v-bind:class="{'is-invalid':errors.id_state}"></autocomplete-component>
	        </div>
	        <div class="col-12 col-md">
	            <label>Traslado a (destino final)</label>
	            <input type="text" class="form-control" v-model="contract.destination_place" v-bind:class="{'is-invalid':errors.service_type_of_client}">
	        </div>
	        <div class="col-12 col-md">
	            <label>Localidad traslado</label>
	            <input type="text" class="form-control" v-model="contract.destination_city" v-bind:class="{'is-invalid':errors.service_type_of_client}">
	        </div>
	        <div class="col-12 col-md">
	            <label>País destino</label>
	            <autocomplete-component v-model="contract.destination_country" v-bind:options="countries" v-bind:key-code="'id'" v-bind:key-label="'name'"></autocomplete-component>
	        </div>
	    </div>
	    <div class="row">
	    	<div class="col-12 col-md-3">
	            <label>Fecha salida</label>
	            <input-date v-model="contract.destination_date_exit" placeholder="Fecha salida" input-class="form-control"></input-date>
	        </div>
	        <div class="col-12 col-md-3">
	            <label>Hora salida</label>
	            <input-time v-model="contract.destination_time_exit" placeholder="Hora salida" input-class="form-control"></input-time>
	        </div>
	        <div class="col-12 col-md-3">
	            <label>Fecha llegada</label>
	    		<input-date v-model="contract.destination_date_entrance" placeholder="Fecha llegada" input-class="form-control"></input-date>
	        </div>
	        <div class="col-12 col-md-3">
	            <label>Hora llegada</label>
	            <input-time v-model="contract.destination_time_entrance" placeholder="Hora llegada" input-class="form-control"></input-time>
	        </div>
	    </div>
	    <div class="row">
	    	<div class="col-12 col-md-6">
	        	<label>Funeraria de recepción</label>
	        	<input type="text" class="form-control" v-model="contract.destination_reception_funeral" v-bind:class="{'is-invalid':errors.destination_wait_in_funeral}">
	        </div>
	        <div class="col-12 col-md-6">
	        	<label>Espera en funeral</label>
	            <input type="text" class="form-control" v-model="contract.destination_wait_in_funeral" v-bind:class="{'is-invalid':errors.destination_wait_in_funeral}">
	        </div>
	        <!--<div class="col-12 col-md-4">
				<label>Gestoría</label>
				<input type="text" class="form-control" v-model="contract.mounting_agency" placeholder="Gestoría"/>
			</div>-->
	        <div class="col-12">
	        	<label>Observaciones destino</label>
	            <input type="text" class="form-control" v-model="contract.destination_reception_observations" v-bind:class="{'is-invalid':errors.destination_reception_observations}">
	        </div>
	    </div>
	    <hr />
	    <legend>Datos inhumación</legend>
	    <div class="row">
	        <div class="col-12 col-md-3">
	            <label>Titular propiedad de enterramiento</label>
	            <input type="text" class="form-control" v-model="contract.inhumation_title" v-bind:class="{'is-invalid':errors.inhumation_title}">
	        </div>
	        <div class="col-12 col-md-3">
	            <label>Delegos firmados</label>
	            <select class="form-control" v-model="contract.inhumation_delegates_signed" v-bind:class="{'is-invalid':errors.inhumation_delegates_signed}">
	            	<option value="1">Sí</option>
	            	<option value="0">No</option>
	            </select>
	        </div>
	        <div class="col-12 col-md-3">
	            <label>Tipo unidad cementerio</label>
	            <input type="text" class="form-control" v-model="contract.inhumation_unit_type_cemetery" v-bind:class="{'is-invalid':errors.inhumation_unit_type_cemetery}">
	        </div>
	        <div class="col-12 col-md-3">
	            <label>Nombre de cementerio</label>
	            <input type="text" class="form-control" v-model="contract.inhumation_cemetery_property" v-bind:class="{'is-invalid':errors.inhumation_cemetery_property}">
	        </div>

	        <div class="col-12 col-md-3">
	            <label>Último inhumado</label>
	            <input type="text" class="form-control" v-model="contract.inhumation_last_inhumated" v-bind:class="{'is-invalid':errors.inhumation_last_inhumated}">
	        </div>
	        <div class="col-12 col-md-3">
	            <label>Reducción</label>
	            <select class="form-control" v-model="contract.inhumation_reduction" v-bind:class="{'is-invalid':errors.inhumation_reduction}">
	            	<option value="1">Sí</option>
	            	<option value="0">No</option>
	            </select>
	        </div>
	        <div class="col-12 col-md-3">
	            <label>2ª Opción</label>
	            <input type="text" class="form-control" v-model="contract.inhumation_second_option" v-bind:class="{'is-invalid':errors.inhumation_second_option}">
	        </div>
	    </div>
	    <hr />
	    <legend>Datos incineración</legend>
	    <div class="row">
	    	<div class="col-12 col-md-4">
	        	<label>Recogida cenizas</label>
	            <select class="form-control" v-model="contract.incineration_ash_pickup" v-bind:class="{'is-invalid':errors.incineration_ash_pickup}">
	            	<option value="declarant">Declarante</option>
	            	<option value="mortuary">Funeraria</option>
	            	<option value="resignation">Renuncia</option>
	            	<option value="cemetery_monstrance">Custodia cementerio</option>
	        	</select>
	        </div>
	        <div class="col-12 col-md-4">
	        	<label>Modelo urna</label>
	            <input type="text" class="form-control" v-model="contract.incineration_urn_model" v-bind:class="{'is-invalid':errors.incineration_urn_model}">
	        </div>
	        <div class="col-12 col-md-4">
	        	<label>Destino cenizas</label>
	            <select class="form-control" v-model="contract.incineration_ash_destination" v-bind:class="{'is-invalid':errors.incineration_ash_destination}">
	            	<option value="declarant">Declarante</option>
	            	<option value="mortuary">Funeraria</option>
	            	<option value="resignation">Renuncia</option>
	            	<option value="cemetery_monstrance">Custodia cementerio</option>
	        	</select>
	        </div>
	        <div class="col-12 col-md-4">
	        	<label>Adornos florales</label>
	            <select class="form-control" v-model="contract.incineration_flower_ornaments" v-bind:class="{'is-invalid':errors.incineration_flower_ornaments}">
	            	<option value="pick">Recoge solicitante</option>
	            	<option value="give">Renuncia</option>
	            </select>
	        </div>
	        <div class="col-12 col-md-4">
	        	<label>Marcapasos</label>
	            <select class="form-control" v-model="contract.incineration_pacemaker" v-bind:class="{'is-invalid':errors.incineration_pacemaker}">
	            	<option value="1">Sí</option>
	            	<option value="0">No</option>
	            </select>
	        </div>
	        <div class="col-12 col-md-4">
	        	<label>Quién realiza la extracción</label>
	            <input type="text" class="form-control" v-model="contract.incineration_extraction" v-bind:class="{'is-invalid':errors.incineration_extraction}">
	        </div>
	    </div>
	    <div class="row">
	    	<div class="col-md-4">
	    		<label>Familia informada</label>
	    		<select class="form-control" v-model="contract.incineration_informed_family" v-bind:class="{'is-invalid':errors.incineration_informed_family}">
	            	<option value="delivery">Entrega</option>
	            	<option value="burring">Entierro</option>
	            	<option value="ashes">Cenizas</option>
	            </select>
	    	</div>
	    	<div class="col-md-4">
	    		<label>Empleado comunica</label>
	    		<input type="text" class="form-control" v-model="contract.incineration_user_comunicate" />
	    	</div>
	    	<div class="col-md-4">
	    		<label>Fecha comunicación</label>
	    		<input-date v-model="contract.incineration_comunication_date" placeholder="Fecha comunicación" input-class="form-control"></input-date>
	    	</div>
	    </div>
	    <hr />
	    <legend>Entrega cenizas</legend>
	    <div class="row">
	    	<div class="col-md-3">
	    		<label>Fecha entrega</label>
	    		<input-date v-model="contract.ash_delivery_date" placeholder="Fecha entrega" input-class="form-control"></input-date>
	    	</div>
	    	<div class="col-md-3">
	    		<label>Hora entrega</label>
	    		<input-time v-model="contract.ash_delivery_time" placeholder="Hora entrega" input-class="form-control"></input-time>
	    	</div>
	    	<div class="col-md-3">
	    		<label>Lugar entrega cenizas</label>
	    		<input type="text" class="form-control" v-model="contract.ash_delivery_place" />
	    	</div>
	    	<div class="col-md-3">
	    		<label>A quién se entrega</label>
	    		<input type="text" class="form-control" v-model="contract.who_receives" />
	    	</div>
	    </div>
	    <hr />
	    <legend>Entierro cenizas</legend>
	    <div class="row">
    		<div class="col-md-3">
	    		<label>Lugar entierro cenizas</label>
	    		<input type="text" class="form-control" v-model="contract.ash_burry_place" placeholder="Lugar entierro cenizas" />
	    	</div>
    		<div class="col-md-3 col-12">
    			<label>Campo de unidad de enterramiento</label>
    			<input type="text" class="form-control" v-model="contract.ash_unit_type" placeholder="Campo de unidad de enterramiento" />
    		</div>
    		<div class="col-md-3 col-12">
    			<label>Temporalidad unidad de enterramiento (años)</label>
    			<input type="text" class="form-control" v-model="contract.ash_columbrarium" placeholder="Temporalidad unidad de enterramiento (años)" />
    		</div>
    		<div class="col-md-3 col-12">
    			<label>Titular unidad de enterramiento</label>
    			<input type="text" class="form-control" v-model="contract.ash_columbrarium_property" placeholder="Titular unidad de enterramiento" />
    		</div>
	    	<div class="col-md-3">
	    		<label>Fecha entierro</label>
	    		<input-date v-model="contract.ash_burry_date" placeholder="Fecha entierro" input-class="form-control"></input-date>
	    	</div>
	    	<div class="col-md-3">
	    		<label>Hora salida</label>
	    		<input-time v-model="contract.ash_exit_time" placeholder="Hora salida" input-class="form-control"></input-time>
	    	</div>
	    	<div class="col-md-3">
	    		<label>Hora entierro</label>
	    		<input-time v-model="contract.ash_burry_time" placeholder="Hora entierro" input-class="form-control"></input-time>
	    	</div>
	    </div>
	    <hr />
		<br>
	    <legend>Partidas literales</legend>
	    <div class="row">
	    	<div class="col-md-4">
	    		<label>Inventarium</label>
	    		<select class="form-control" v-model="contract.service_inventorum">
	            	<option value="1">Sí</option>
	            	<option value="0">No</option>
	            </select>
	    	</div>
	    	<div class="col-md-4">
	    		<label>Fecha solicitada</label>
	    		<input-date v-model="contract.service_inventorum_request_date" placeholder="Fecha solicitada" input-class="form-control"></input-date>
	    	</div>
	    	<div class="col-md-4">
	    		<label>Fecha recogida</label>
	    		<input-date v-model="contract.service_inventorum_delivered_date" placeholder="Fecha recogida" input-class="form-control"></input-date>
	    	</div>
	    </div>
	    <div class="row">
	    	<div class="col-md-3">
	    		<label>¿Se ha enviado?</label>
	    		<select class="form-control" v-model="contract.service_inventorum_sent">
	            	<option value="1">Sí</option>
	            	<option value="0">No</option>
	            </select>
	    	</div>
	    	<div class="col-md-3">
	    		<label>Fecha envío</label>
	    		<input-date v-model="contract.service_inventorum_sent_date" placeholder="Fecha envío" input-class="form-control"></input-date>
	    	</div>
	    	<div class="col-md-3">
	    		<label>Dirección de envío</label>
	    		<input type="text" v-model="contract.service_inventorum_delivery_address" placeholder="Dirección de envío" class="form-control" />
	    	</div>
	    	<div class="col-md-3">
	    		<label>Dirección de email</label>
	    		<input type="text" v-model="contract.service_inventorum_delivery_email" placeholder="Dirección de email" class="form-control" />
	    	</div>
	    </div>
	</div>
</template>
<script>
	export default{
		props: ['contract','countries','states','cementeries'],
		data(){
			return {
      			errors:{

          		}
			}
		}
	}
</script>