<template>
	<div class="modal fade action-sheet opax" id="coffiningModal" tabindex="-1" role="dialog">
	    <div class="modal-dialog modal-md" role="document">
	    	<div class="modal-content">
			    <div class="modal-header">
				    <h5 class="modal-title">{{ coffining.id?'Editar enferetramiento':'Nuevo enferetramiento'}}</h5>
				    <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-on:click="cancel();">
				    <span aria-hidden="true">&times;</span>
				    </button>
				</div>
				<div class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            <label>Nombre legal *</label>
                            <input type="text" class="form-control" v-model="coffining.legal_name" />
                        </div>
                        <div class="col-12">
                            <label>Nombre comercial *</label>
                            <input type="text" class="form-control" v-model="coffining.tradename" />
                        </div>
                        <div class="col-12">
                            <label>Dirección *</label>
                            <input type="text" class="form-control" v-model="coffining.address" />
                        </div>
                        <div class="col-12">
                            <label>CIF *</label>
                            <input type="text" class="form-control" v-model="coffining.cif" />
                        </div>
                        <div class="col-12">
                            <label>Teléfono *</label>
                            <input type="text" class="form-control" v-model="coffining.phone_number" />
                        </div>
                    </div>
				</div>
				<div class="modal-footer">
				    <button type="button" class="btn btn-success mr-2" v-on:click="save();" v-bind:disabled="saving"><i class="fa fa-spin fa-spinner" v-show="saving"></i> Guardar</button>
				    <button type="button" class="btn btn-danger" data-dismiss="modal" v-on:click="cancel();">Cancelar</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
    export default {
        mounted() {
            this.$root.$on('coffining.add',this.openModal);
            this.$root.$on('coffining.edit',this.openModal);
        },
        data(){
            return {
            	base:window.urls['coffining.index'],
                saving: false,
                coffining:{
                    legal_name: null,
                    address: null,
                    tradename: null,
                    cif: null,
                    phone_number: null,
                },
                errors: {
                    legal_name: false,
                    address: false,
                    tradename: false,
                    cif: false,
                    phone_number: false,
                },
            }
        },
        methods:{
        	openModal(data){
                if(data){
                    this.coffining={
                        id: data.id,
                        legal_name: data.legal_name,
                        address: data.address,
                        tradename: data.tradename,
                        cif: data.cif,
                        phone_number: data.phone_numbe
                    };
                }else{
                    this.coffining={
                        legal_name: null,
                        address: null,
                        tradename: null,
                        cif: null,
                        phone_number: null,
                    };
                }
                this.resetErrors();
        		$('#coffiningModal').modal({'backdrop':'static'});
        	},
            resetErrors(){
                this.errors = {
                    legal_name: false,
                    address: false,
                    tradename: false,
                    cif: false,
                    phone_number: false,
                };
            },
            firstLetterOnUpperCase(field){
                var aux=this.coffining[field].split(" ");
                var final="";
                for(var i=0;i<aux.length;i++){
                    final+=aux[i].charAt(0).toUpperCase() + aux[i].slice(1);
                    if(i<aux.length-1) final+=" ";
                }
                this.coffining[field]=final;
            },
            validate(){
                var valid = true;
                this.errors.legal_name = this.coffining.legal_name == null || this.coffining.legal_name == "";
                this.errors.address = this.coffining.address == null || this.coffining.address == "";
                this.errors.tradename = this.coffining.tradename == null || this.coffining.tradename == "";
                this.errors.cif = this.coffining.cif == null || this.coffining.cif == "";
                this.errors.phone_number = this.coffining.phone_number == null || this.coffining.phone_number == "";
                for(var i in this.errors)
                    valid &= !this.errors[i];
                if(!valid)
                    this.showRequiredMessage();
                return valid;
            },
            showRequiredMessage(){
                this.$swal(
                    'Atención',
                    'Hay datos obligatorios por rellenar',
                    'warning'
                );
            },
            save(){
                if(!this.validate())
                    return;
                var url = this.base;
                var vm = this;
                if(this.coffining.id)
                    url += '/' + this.coffining.id;
                this.saving=true;
                axios({
                    method: 'post',
                    url: url,
                    data: this.coffining,
                    responseType: 'json',
                }).then(function(response){
                    vm.saving=false;
                    vm.$root.$emit('coffining.updated', response.data);
                    vm.cancel();
                    vm.$swal(
                      'Correcto',
                      'Se ha guardado la empresa de enferetramiento',
                      'success'
                    );
                })
                .catch(function (error) {
                    vm.saving = false;
                    var message = 'Parece que algo fue mal';
                    if(error.response.status == 400)
                        message = error.response.data.message;
                    if(error.response.status == 404)
                        message = "El registro ya no existe";
                    vm.$swal(
                      '¡Ups!',
                      message,
                      'error'
                    );
                });
            },
            cancel(){
            	$('#coffiningModal').modal('hide');
            }
        }
    }
</script>
