<template>
	<div class="modal fade action-sheet opax" id="userModal" tabindex="-1" role="dialog">
	    <div class="modal-dialog modal-md" role="document">
	    	<div class="modal-content">
			    <div class="modal-header">
				    <h5 class="modal-title">{{ user.id?'Editar usuario':'Nuevo usuario'}}</h5>
				    <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-on:click="cancel();">
				    <span aria-hidden="true">&times;</span>
				    </button>
				</div>
				<div class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            <label>Nombre *</label>
                            <input spellcheck="true" type="text" class="form-control" v-model="user.name" v-on:blur="firstLetterOnUpperCase('name')" placeholder="Nombre" v-bind:class="{'is-invalid':errors.name}"/>
                        </div>
                        <div class="col-12">
                            <label>Apellidos *</label>
                            <input spellcheck="true" type="text" class="form-control" v-model="user.lastname" v-on:blur="firstLetterOnUpperCase('lastname')" placeholder="Apellidos" v-bind:class="{'is-invalid':errors.lastname}"/>
                        </div>
                        <div class="col-12">
                            <label>Perfil *</label>
                            <select type="text" class="form-control" v-model="user.id_role" v-bind:class="{'is-invalid':errors.id_role}">
                                <option v-for="r in roles" v-bind:value="r.id">{{ r.role }}</option>
                            </select>
                        </div>
                        <div class="col-12">
                            <label>Empresa *</label>
                            <select type="text" class="form-control" v-model="user.company_id" v-bind:class="{'is-invalid':errors.company_id}">
                                <option v-for="c in companies" v-bind:value="c.id">{{ c.name }}</option>
                            </select>
                        </div>
                        <div class="col-12">
                            <label>Correo electrónico *</label>
                            <input spellcheck="true" type="email" class="form-control" v-model="user.email"  placeholder="Correo electrónico" v-bind:class="{'is-invalid':errors.email}"/>
                        </div>
                        <div class="col-12">
                            <label>Contraseña <sup v-if="!user.id">*</sup></label>
                            <input type="password" class="form-control" v-model="user.password" placeholder="Contraseña" v-bind:class="{'is-invalid':errors.password}"/>
                        </div>
                    </div>
				</div>
				<div class="modal-footer">
				    <button type="button" class="btn btn-success mr-2" v-on:click="save();" v-bind:disabled="saving"><i class="fa fa-spin fa-spinner" v-show="saving"></i> Guardar</button>
				    <button type="button" class="btn btn-danger" data-dismiss="modal" v-on:click="cancel();">Cancelar</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
    export default {
        mounted() {
            this.$root.$on('users.add',this.openModal);
            this.$root.$on('users.edit',this.openModal);
            this.getRoles();
            this.getCompanies();
        },
        data(){
            return {
            	base: window.urls['users.index'],
                baseRoles: window.urls['roles.index'],
                baseCompanies: window.urls['companies.index'],
                roles:[],
                companies:[],
                saving: false,
                user:{
                    id_role: null,
                    name: null,
                    lastname: null,
                    email: null,
                    company_id: null,
                },
                errors: {
                    id_role: false,
                    name: false,
                    lastname: false,
                    email: false
                },
            }
        },
        methods:{
            getRoles(){
                var vm=this;
                axios({
                    method: 'get',
                    url: this.baseRoles+'/all',
                    responseType: 'json',
                }).then(function(response){
                    vm.roles=response.data;
                })
                .catch(function (error) {
                    vm.getting=false;
                    vm.$swal(
                      '¡Ups!',
                      'Parece que algo fue mal',
                      'error'
                    );  
                }); 
            },
            getCompanies(){
                var vm=this;
                axios({
                    method: 'get',
                    url: this.baseCompanies+'/all',
                    responseType: 'json',
                }).then(function(response){
                    vm.companies = response.data;
                })
                .catch(function (error) {
                    vm.getting=false;
                    vm.$swal(
                      '¡Ups!',
                      'Parece que algo fue mal',
                      'error'
                    );  
                }); 
            },
            checkIfNumber(evt){
                var charCode = (evt.which) ? evt.which : evt.keyCode;
                if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                    evt.preventDefault();;
                } else {
                    return true;
                }
            },
        	openModal(data){
                if(data){
                    this.user={
                        id: data.id,
                        id_role: data.id_role,
                        name: data.name,
                        lastname: data.lastname,
                        email: data.email,
                        company_id: data.company_id,
                    };
                }else{
                    this.user={
                        id_role: null,
                        name: null,
                        lastname: null,
                        email: null,
                        password: null,
                        company_id: null,
                    };
                }
                this.resetErrors();
        		$('#userModal').modal({'backdrop':'static'});
        	},
            resetErrors(){
                this.errors={
                    id_role: false,
                    name: false,
                    lastname: false,
                    email: false,
                };
            },
            firstLetterOnUpperCase(field){
                var aux=this.user[field].split(" ");
                var final="";
                for(var i=0;i<aux.length;i++){
                    final+=aux[i].charAt(0).toUpperCase() + aux[i].slice(1);
                    if(i<aux.length-1) final+=" ";
                }
                this.user[field]=final;
            },
            validate(){
                var valid = true;
                this.errors.id_role = this.user.id_role == null || this.user.id_role == "";
                this.errors.name = this.user.name == null || this.user.name == "";
                this.errors.lastname = this.user.lastname == null || this.user.lastname == "";
                this.errors.email = this.user.email == null || this.user.email == "";
                this.errors.password = this.user.id == null && (this.user.password == null || this.user.password == "");
                for(var i in this.errors)
                    valid &= !this.errors[i];
                if(!valid)
                    this.showRequiredMessage();
                return valid;
            },
            showRequiredMessage(){
                this.$swal(
                    'Atención',
                    'Hay datos obligatorios por rellenar',
                    'warning'
                );
            },
            save(){
                if(!this.validate())
                    return;
                var url=this.base;
                var vm=this;
                if(this.user.id) url += '/' + this.user.id;
                this.saving=true;
                axios({
                    method: 'post',
                    url: url,
                    data: this.user,
                    responseType: 'json',
                }).then(function(response){
                    vm.saving=false;
                    vm.$root.$emit('users.updated');
                    vm.cancel();
                    vm.$swal(
                      'Correcto',
                      'Se ha guardado el comercial',
                      'success'
                    );
                })
                .catch(function (error) {
                    vm.saving = false;
                    var message = 'Parece que algo fue mal';
                    if(error.response.status == 400)
                        message = error.response.data.message;
                    if(error.response.status == 404)
                        message = "El registro ya no existe";
                    vm.$swal(
                      '¡Ups!',
                      message,
                      'error'
                    );
                });
            },
            cancel(){
            	$('#userModal').modal('hide');
            }
        }
    }
</script>
