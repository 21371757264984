<template>
	<div class="modal fade action-sheet opax" id="cementeryModal" tabindex="-1" role="dialog">
	    <div class="modal-dialog modal-md" role="document">
	    	<div class="modal-content">
			    <div class="modal-header">
				    <h5 class="modal-title">{{ cementery.id?'Editar cementerio':'Nuevo cementerio'}}</h5>
				    <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-on:click="cancel();">
				    <span aria-hidden="true">&times;</span>
				    </button>
				</div>
				<div class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            <label>Nombre *</label>
                            <input spellcheck="true" type="text" class="form-control" v-model="cementery.name" v-on:blur="firstLetterOnUpperCase('name')" placeholder="Nombre" v-bind:class="{'is-invalid':errors.name}"/>
                        </div>
                        <div class="col-12">
                            <label>Provincia *</label>
                            <input spellcheck="true" type="text" class="form-control" v-model="cementery.state" v-on:blur="firstLetterOnUpperCase('state')" placeholder="Provincia" v-bind:class="{'is-invalid':errors.name}"/>
                        </div>
                        <div class="col-12">
                            <label>Municipio *</label>
                            <input spellcheck="true" type="text" class="form-control" v-model="cementery.city" v-on:blur="firstLetterOnUpperCase('city')" placeholder="Municipio" v-bind:class="{'is-invalid':errors.city}"/>
                        </div>
                    </div>
				</div>
				<div class="modal-footer">
				    <button type="button" class="btn btn-success mr-2" v-on:click="save();" v-bind:disabled="saving"><i class="fa fa-spin fa-spinner" v-show="saving"></i> Guardar</button>
				    <button type="button" class="btn btn-danger" data-dismiss="modal" v-on:click="cancel();">Cancelar</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
    export default {
        mounted() {
            this.$root.$on('cementeries.add',this.openModal);
            this.$root.$on('cementeries.edit',this.openModal);
        },
        data(){
            return {
            	base:window.urls['cementeries.index'],
                saving: false,
                cementery:{
                    name: null,
                    state: null,
                    city: null,
                },
                errors: {
                    name: false,
                    state: false,
                    city: false,
                },
            }
        },
        methods:{
        	openModal(data){
                if(data){
                    this.cementery={
                        id: data.id,
                        name: data.name,
                        state: data.state,
                        city: data.city,
                    };
                }else{
                    this.cementery={
                        name: null,
                        state: null,
                        city: null
                    };
                }
                this.resetErrors();
        		$('#cementeryModal').modal({'backdrop':'static'});
        	},
            resetErrors(){
                this.errors={
                    name: false,
                    state: false,
                    city: false
                };
            },
            firstLetterOnUpperCase(field){
                var aux=this.cementery[field].split(" ");
                var final="";
                for(var i=0;i<aux.length;i++){
                    final+=aux[i].charAt(0).toUpperCase() + aux[i].slice(1);
                    if(i<aux.length-1) final+=" ";
                }
                this.cementery[field]=final;
            },
            validate(){
                var valid = true;
                this.errors.name = this.cementery.name == null || this.cementery.name == "";
                this.errors.state = this.cementery.state == null || this.cementery.state == "";
                this.errors.city = this.cementery.city == null || this.cementery.city == "";
                
                for(var i in this.errors)
                    valid &= !this.errors[i];
                if(!valid)
                    this.showRequiredMessage();
                return valid;
            },
            showRequiredMessage(){
                this.$swal(
                    'Atención',
                    'Hay datos obligatorios por rellenar',
                    'warning'
                );
            },
            save(){
                if(!this.validate())
                    return;
                var url=this.base;
                var vm=this;
                if(this.cementery.id) url += '/' + this.cementery.id;
                this.saving=true;
                axios({
                    method: 'post',
                    url: url,
                    data: this.cementery,
                    responseType: 'json',
                }).then(function(response){
                    vm.saving=false;
                    vm.$root.$emit('cementeries.updated');
                    vm.cancel();
                    vm.$swal(
                      'Correcto',
                      'Se ha guardado el cementerio',
                      'success'
                    );
                })
                .catch(function (error) {
                    vm.saving = false;
                    var message = 'Parece que algo fue mal';
                    if(error.response.status == 400)
                        message = error.response.data.message;
                    if(error.response.status == 404)
                        message = "El registro ya no existe";
                    vm.$swal(
                      '¡Ups!',
                      message,
                      'error'
                    );
                });
            },
            cancel(){
            	$('#cementeryModal').modal('hide');
            }
        }
    }
</script>
