<template>
	<div class="modal fade action-sheet opax" id="inoutAdminModal" tabindex="-1" role="dialog">
	    <div class="modal-dialog modal-md" role="document">
	    	<div class="modal-content">
			    <div class="modal-header">
				    <h5 class="modal-title">Modificar fichaje</h5>
				    <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-on:click="cancel();">
				    <span aria-hidden="true">&times;</span>
				    </button>
				</div>
				<div class="modal-body">
				    <div class="row">
				        <div class="col-12 col-md-6">
                            <button class="btn w-100" v-bind:class="{'btn-default':inout.type != 'in','btn-success':inout.type == 'in'}" v-bind:disabled="saving" v-on:click="setType('in')"><i class="fa fa-spin fa-spinner" v-show="saving"></i> Entrada</button>
				        </div>
                        <div class="col-12 col-md-6 mt-md-0 mt-3">
                            <button class="btn w-100" v-bind:class="{'btn-default':inout.type != 'out','btn-success':inout.type == 'out'}" v-bind:disabled="saving" v-on:click="setType('out')"><i class="fa fa-spin fa-spinner" v-show="saving"></i> Salida</button>
                        </div>
                        <div class="form-user col-12" v-if="inout.type">
                            <label>Trabajador</label>
                            <v-select v-on:search="searchWorkers" v-bind:options="workers" v-model="worker" v-on:input="selectWorker" :filter="customSearch">
                                <template slot="spinner">
                                    <div class="spinner" v-show="spinner"><i class="fa fa-spinner fa-spin"></i></div>
                                </template>
                                <template #no-options="{ search, searching, loading }">
                                    Ninguna coincidencia
                                </template>
                            </v-select>
                        </div>
                        <div class="col-12 mt-md-0 mt-3" v-show="inout.user_id">
                            <label>Fecha y hora de entrada</label>
                            <datepicker v-model="inout.date" style="display: block;width: 100%"  v-bind:lang="lang" value-type="YYYY-MM-DD HH:mm:ss" format="DD/MM/YYYY HH:mm" type="datetime" placeholder="" input-class="form-control"></datepicker>
                        </div>
                        <div class="col-12 mt-3" v-show="inout.user_id">
                            <label>¿Algún comentario?</label>
                            <textarea v-model="inout.comments" class="form-control" placeholder="Comentario"></textarea>
                        </div>
				    </div>
				</div>
				<div class="modal-footer">
					<a v-show="path != base" class="btn btn-info" v-bind:href="base">Histórico</a>
                    <div class="ml-auto">
    				    <button type="button" class="btn btn-success mr-2" v-on:click="save();" v-bind:disabled="saving"><i class="fa fa-spin fa-spinner" v-show="saving"></i> Modificar</button>
    				    <button type="button" class="btn btn-danger" data-dismiss="modal" v-on:click="cancel();">Cancelar</button>
                    </div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
    import Datepicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import 'vue2-datepicker/locale/es';
    export default {
        components: { Datepicker },
        mounted() {
            if(this.isAdmin)
                this.$root.$on('inout.add',this.openModal);
            this.$root.$on('inout.edit',this.openModal);
            this.getContext();
        },
        data(){
            return {
                isAdmin: user_role == 'admin',
            	base: window.urls['inout.index'],
            	path: "/" + window.location.pathname.replace("/",""),
                saving: false,
                spinner: false,
                allWorkers: [],
                workers: [],
                worker: null,
                lang: {
					formatLocale: {
	          			firstDayOfWeek: 1,
	              	},
	              	monthBeforeYear: false,
	          	},
                inout:{
                    user_id: null,
                    type: null,
                    date: null,
                    comments: null,
                },
                errors: {
                	type: false
                }
            }
        },
        filters: {
        	dayFormat(input){
        		return moment(input).format('DD/MM/YYYY');
        	},
        	hourFormat(input){
        		return moment(input).format('HH:mm');
        	}
        },
        methods:{
            getContext(){
                var vm = this;
                axios({
                    method: 'get',
                    url: this.base + '/context',
                    responseType: 'json',
                }).then(function(response){
                    var workers = response.data.workers;
                    for(var i in workers){
                        vm.allWorkers.push({
                            code: workers[i].id,
                            label: vm.formatWorkerLabel(workers[i])
                        });
                        vm.workers.push({
                            code: workers[i].id,
                            label: vm.formatWorkerLabel(workers[i])
                        });
                    }
                })
            },
        	openModal(data){
                if(!data){
                    this.inout = {
                        user_id: null,
                        type: null,
                        date: null,
                        comments: null
                    };
                    this.worker = null;
                }
                else{
                    this.inout = {
                        id: data.id,
                        user_id: data.user_id,
                        type: data.type,
                        date: data.date,
                        comments: data.comments
                    };
                    this.worker = {
                        code: data.user_id,
                        label: this.formatWorkerLabel(data.user)
                    }
                }
        		this.saving = false;
        		this.resetErrors();
        		$('#inoutAdminModal').modal({'backdrop':'static'});
        	},
            customSearch(options, search) {
              return options;
            },
            formatWorkerLabel(worker){
                return worker.name + ' ' + worker.lastname;
            },
            searchWorkers(search){
                if(!search)
                    this.workers = JSON.parse(JSON.stringify(this.allWorkers));
                var finalSearch = search.toLowerCase();
                this.workers = [];
                for(var i in this.allWorkers){
                    if(this.allWorkers[i].label.toLowerCase().indexOf(finalSearch) != -1){
                        this.workers.push(this.allWorkers[i]);
                    }
                }
            },
            selectWorker(value){
                if(value != null)
                    this.inout.user_id = value.code;
                else this.inout.user_id = null;
            },
            setType(type){
                this.inout.type = type;
            },
          	resetErrors(){
                this.errors={
                    type: false,
                };
            },
            validate(){
                var valid = true;
                this.errors.type = this.inout.type == null || this.inout.type == "";
                for(var i in this.errors)
                    valid &= !this.errors[i];
                if(!valid)
                    this.showRequiredMessage();
                return valid;
            },
            showRequiredMessage(){
                this.$swal(
                    'Atención',
                    'Debes indicar si estás registrando una entrada o una salida',
                    'warning'
                );
            },
            save(){
                var url = this.base;
                var vm = this;
                if(this.inout.id)
                	url += '/' + this.inout.id;
                this.saving = true;
                console.log(this.inout);
                axios({
                    method: 'post',
                    url: url,
                    data: this.inout,
                    responseType: 'json',
                }).then(function(response){
                    vm.saving = false;
                    vm.$root.$emit('inout.updated');
                    vm.cancel();
                    vm.$swal(
                      'Correcto',
                      'Has fichado correctamente',
                      'success'
                    );
                })
                .catch(function (error) {
                    vm.saving=false;
                    console.log(error.response);
                    vm.$swal(
                      '¡Ups!',
                      'Parece que algo fue mal',
                      'error'
                    );  
                });
            },
            cancel(){
            	$('#inoutAdminModal').modal('hide');
            }
        }
    }
</script>
