<template>
	<div class="modal fade action-sheet opax" id="budgetsListtModal" tabindex="-1" role="dialog">
	    <div class="modal-dialog modal-xl" role="document">
	    	<div class="modal-content">
			    <div class="modal-header">
				    <h5 class="modal-title">Presupuestos enviados</h5>
				    <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-on:click="cancel();">
				    <span aria-hidden="true">&times;</span>
				    </button>
				</div>
				<div class="modal-body">
                    <div class="table-responsive" v-if="budgets.length > 0">
                    	<table class="table">
                    		<thead>
                    			<tr>
                    				<th>Nº</th>
                    				<th>Fecha</th>
                    				<th>Base</th>
                    				<th>IVA</th>
                    				<th>Desc.</th>
                    				<th class="text-right">Total</th>
                    				<th></th>
                    			</tr>
                    		</thead>
                    		<tbody>
                    			<tr v-for="(r, index) in budgets">
                    				<th>
                    					{{ r|formatNumber }}
                    				</th>
                    				<td>
                    					{{ r.created_at|dayFormat }}
                    				</td>
                    				<td>
                    					{{ r.base|round }}
                    				</td>
                    				<td>
                    					{{ r.iva|round }}
                    				</td>
                    				<td>
                    					{{ r.discount|round }}
                    				</td>
                    				<td align="right">
                    					{{ r.total|round }}
                    				</td>
                    				<td align="right">
                    					<a v-bind:href="base + '/print/' + r.id" target="_blank">Ver</a>
                    				</td>
                    			</tr>
                    		</tbody>
                    	</table>
                    </div>
				</div>
				<div class="modal-footer">
				    <button type="button" class="btn btn-danger" data-dismiss="modal" v-on:click="cancel();">Cerrar</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
    export default {
        mounted() {
            this.$root.$on('contacts.budgets',this.openModal);
        },
        filters: {
        	dayFormat(input){
                if(input) return moment(input).format('DD/MM/YYYY')
                return "";
            },
        	round(input){
        		if(input)
        			return parseFloat(input).toFixed(2);
        		return "0.00";
        	},
        	formatNumber(input){
        		var date = input.created_at.substring(0, 4);
        		var number = String("00000" + input.id).slice(-3);
        		return date + "/" + number;
        	},
        },
        data(){
            return {
            	base: window.urls['contacts.budget.index'],
                budgets:[],
                contact: null,
            }
        },
        methods:{
        	openModal(data){
        		this.contact = data;
        		this.budgets = data.budgets;
        		$('#budgetsListtModal').modal({'backdrop':'static'});
        	},
        	send(){
                if(this.rows.length == 0)
                    return;
                var url = this.base;
                var vm = this;
                this.saving=true;
                axios({
                    method: 'post',
                    url: url,
                    data: {
                    	contact: this.contact,
                    	rows: this.rows
                    },
                    responseType: 'json',
                }).then(function(response){
                    vm.saving=false;
                    vm.$root.$emit('contacts.updated');
                    vm.cancel();
                    vm.$swal(
                      'Correcto',
                      'Se ha guardado el presupuesto',
                      'success'
                    );
                })
                .catch(function (error) {
                    vm.saving = false;
                    var message = 'Parece que algo fue mal';
                    if(error.response.status == 400)
                        message = error.response.data.message;
                    if(error.response.status == 404)
                        message = "El registro ya no existe";
                    vm.$swal(
                      '¡Ups!',
                      message,
                      'error'
                    );
                });
        	},
            cancel(){
            	$('#budgetsListtModal').modal('hide');
            }
        }
    }
</script>
