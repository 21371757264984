<template>    
    <v-server-table ref="dataTable" v-bind:url="base+'/table'" v-bind:columns="columns" v-bind:options="options" v-bind:template="'bootstrap4'" @loading="loadingTable" @loaded="loadedTable">
        <div slot="user_id" slot-scope="props">
            {{ props.row.user?props.row.user.name+' '+props.row.user.lastname:'' }}
        </div>
        <div slot="date" slot-scope="props">
            {{ props.row.date|dayFormat }}
        </div>
        <div slot="hour" slot-scope="props">
            {{ props.row.date|hourFormat }}
        </div>
        <div slot="method" slot-scope="props">
            {{ props.row.method|transformMethod }}
        </div>
        <div slot="type" slot-scope="props">
            {{ props.row.type|typeFormat }}
        </div>
        <div slot="hours" slot-scope="props">
            <span v-if="props.row.type == 'out' && props.row.hours != null">{{ props.row.hours|strpad }}:{{ props.row.minutes|strpad }}</span>
        </div>
        <div slot="actions" slot-scope="props">
            <a href="javascript:void(0);" v-on:click="edit(props.row)" class="text-inverse text-sm" title="" data-toggle="tooltip" data-original-title="Editar"><i class="fas fa-edit"></i></a>
        </div>
    </v-server-table>
</template>

<script>
    export default {
        data(){
            var startOfMonth = moment().startOf('month').format('YYYY-MM-DD') + ' 00:00:00';
            var endOfMonth   = moment().endOf('month').format('YYYY-MM-DD') + ' 23:59:59';
        	return {
        		base: window.urls['inout.index'],
                loading:true,
                firsttime:true,
                isAdmin: user_role == 'admin',
                columns: [
                    'user_id',
                    'type',
                    'date',
                    'hour',
                    'hours',
                    'from',
                    'to'
                ],
		        options:{
                    sortIcon: {
                        base : 'float-right fa',
                        is: 'fa-sort',
                        up: 'fa-sort-up',
                        down: 'fa-sort-down'
                    },
                	headings:{'user_id':'Nombre','type':'Tipo','date':'Fecha','method':'Método','details':'Información','hour':'Hora','hours':'Horas trabajadas','actions':'Acciones'},
                    skin: 'table table-striped',
                    filterable:true,
		        	texts: {
                        count: '',
                        filter:'Buscar: ',
                        filterPlaceholder:"...",
                        defaultOption:'Elegir {column}',
                        filterBy:"Filtrar por {column}",
                        loading:'Cargando...',
                        limit:"Mostrando:",
                        noResults:'No hay resultados'
                    },
                    initFilters: {
                        from: startOfMonth,
                        to: endOfMonth
                    },
                	orderBy:{
                		ascending:0,
                		column:'created_at'
                    },
                    rowClassCallback: row => {
                        if(row.type == 'in' || row.hours == null)
                            return ;

                        var hours = parseInt(row.hours);
                        var minutes = parseInt(row.hours) / 60;
                        if(hours + minutes > 8)
                            return 'bg-warning';
                    },
                    filterByColumn: true,
                    hiddenColumns:['from','to']
		        }
        	}
	  	},
	  	filters:{
            dayFormat(input){
                return moment(input).format('DD/MM/YYYY')
            },
            hourFormat(input){
                return moment(input).format('HH:mm')
            },
            typeFormat(input){
                switch(input){
                    case 'in': return 'Entrada';
                    case 'out': return 'Salida';
                }
            },
            round(input){
                return parseInt(input.toString());
            },
            transformMethod(input){
                switch(input){
                    case 'location': return 'Geolocalización';
                    case 'ip': return 'IP';
                }
            },
            strpad(input){
                return String("0" + input).slice(-2);
            }
        },
        beforeMount(){
            if(this.isAdmin)
                this.columns.unshift('actions');
        },
        mounted(){
        	this.$root.$on('inout.search',this.search);
            this.$root.$on('inout.updated',this.search);
        },
        methods:{
            loadingTable(){
                this.loading=true;
            },
            loadedTable(data){
                this.loading=false;
                this.firsttime=false;
                this.$root.$emit('inout.loaded',data.data);
            },
            search(filter){
                this.$refs.dataTable.setFilter(filter);
            },
            edit(row){
                this.$root.$emit('inout.edit',row);
            },
        	deleteRow(row){
	  			var vm=this;
	  			this.$swal({
					title: "Eliminar",
					text: "No podrás deshacer esta acción",
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Sí',
				}).then((result) => {
                    if(result.value){
                        axios({
		                    method: 'delete',
		                    url: this.base+'/'+row.id,
		                    responseType: 'json',
		                }).then(function(response){
		                    vm.$refs.dataTable.refresh();
		                })
		                .catch(function (error) {
		                    vm.$swal(
		                      '¡Ups!',
		                      'Parece que algo fue mal',
		                      'error'
		                    );  
		                });
                    }
				})
	  		}
        }
    }
</script>
