<template>
	<div class="modal fade action-sheet opax" id="clientDocumentsModal" tabindex="-1" role="dialog">
	    <div class="modal-dialog modal-lg" role="document">
	    	<div class="modal-content">
			    <div class="modal-header">
				    <h5 class="modal-title">Documentación</h5>
				    <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-on:click="cancel();">
				    <span aria-hidden="true">&times;</span>
				    </button>
				</div>
				<div class="modal-body" v-if="client">
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <label class="d-block">DNI (frontal)</label>
                            <div v-if="front" v-show="!newFront">
                                <file-preview v-bind:file="front"></file-preview>
                            </div>
                            <button v-if="!newFront" class="btn btn-info mt-3" v-on:click="clickOnDocument('#dni_front')">
                                {{ front?'Modificar':'Añadir' }}
                            </button>
                            <div class="d-none">
                                <input-file id="dni_front" accept="image/*,application/pdf" v-model="newFront"></input-file>
                            </div>
                            <div v-if="newFront">
                                <file-preview v-bind:file="newFront[0]"></file-preview>
                            </div>
                            <div v-if="newFront" class="mt-3 d-flex">
                                <span>{{ transformToHuman(newFront[0].size) }}</span>
                                <button class="btn btn-danger btn-sm ml-auto" v-on:click="removeIdFront()">
                                    <i class="fas fa-trash"></i>
                                </button>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <label class="d-block">DNI (trasera)</label>
                            <div v-show="back && !newBack">
                                <file-preview v-bind:file="back"></file-preview>
                            </div>
                            <button v-if="!newBack" class="btn btn-info mt-3" v-on:click="clickOnDocument('#dni_back')">
                                {{ back?'Modificar':'Añadir' }}
                            </button>
                            <div class="d-none">
                                <input-file id="dni_back" accept="image/*,application/pdf" v-model="newBack"></input-file>
                            </div>
                            <div v-if="newBack">
                                <file-preview v-if="newBack" v-bind:file="newBack[0]"></file-preview>
                            </div>
                            <div  v-if="newBack" class="mt-3 d-flex">
                                <span>{{ transformToHuman(newBack[0].size) }}</span>
                                <button class="btn btn-danger btn-sm ml-auto" v-on:click="removeIdBack()">
                                    <i class="fas fa-trash"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                    		<hr class="my-3"/>
                    	</div>
                    </div>
                    <h3>Adicional ({{ totalFiles }}/{{ maxDocuments }})</h3>
                    <div class="alert alert-danger" v-if="totalSize > maxSize">
                        Estás intentando enviar {{ transformToHuman(totalSize) }} en documentos, el máximo por cada subida es de  {{ transformToHuman(maxSize) }}
                    </div>
                    <div class="row">
                        <div class="col-12 mb-3" v-if="!isOverTotalDocuments()">
                            <button class="btn btn-info" v-on:click="clickOnDocument('#extra')">Añadir</button>
                            <div class="d-none">
                                <input-file id="extra" accept="image/*,application/pdf" v-model="newOthers" multiple></input-file>
                            </div>
                        </div>
                        <div class="col-4 col-md-3 mb-3" v-for="(d,index) in documentsToAdd" v-bind:key="d.id">
                            <div class="file-wrapper">
                                <file-preview v-bind:file="d"></file-preview>
                                <div class="mt-2 d-flex">
                                    <span>{{ transformToHuman(d.size) }}</span>
                                    <button class="btn btn-danger btn-sm ml-auto" v-on:click="removeNewDocument(index)">
                                        <i class="fas fa-trash"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="col-4 col-md-3 mb-3" v-for="(d,index) in others">
                            <div class="file-wrapper">
                                <file-preview v-bind:file="d"></file-preview>
                                <div class="mt-2 d-flex">
                                    <span>{{ transformToHuman(d.size) }}</span>
                                    <button class="btn btn-danger btn-sm ml-auto" v-show="d.id != idDeleting" v-on:click="removeDocument(d, index)">
                                        <i class="fas fa-trash"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
				</div>
				<div class="modal-footer">
				    <button type="button" class="btn btn-success mr-2" v-on:click="save();" v-bind:disabled="saving"><i class="fa fa-spin fa-spinner" v-show="saving"></i> Guardar</button>
				    <button type="button" class="btn btn-danger" data-dismiss="modal" v-on:click="cancel();">Cancelar</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
    export default {
        mounted() {
            this.$root.$on('clients.documents.add', this.openModal);
        },
        data(){
            return {
            	base: window.urls['clients.documents.index'],
                maxDocuments: 8,
                maxSize: 167772160,
                saving: false,
                birthDate: null,
                newFront: null,
                newBack: null,
                newOthers: [],

                newFrontUrl: null,
                newBackUrl: null,

                front: null,
                back: null,
                others: [],
                documentsToAdd: [],
                client: null,
                totalSize: 0,
                totalFiles: 0,
                idDeleting: null
            }
        },
        watch: {
            newOthers: function(newDocuments){
                if(newDocuments.length > 0){
                    for(var i = 0; i < newDocuments.length; i++){
                        if(this.documentsToAdd.length < 8)
                            this.documentsToAdd.push(newDocuments[i]);
                    }
                }
                this.calculateTotalSize();
            }
        },
        methods: {
        	openModal(data){
                this.client = data;
                this.newFront = null;
				this.newBack = null;
				this.newOthers = [];
                this.documentsToAdd = [];
                this.totalSize = 0;
                this.initializeDocuments(data);
                this.resetErrors();
        		$('#clientDocumentsModal').modal({'backdrop':'static'});
        	},
            removeIdFront(){
                this.newFront = null;
            },
            removeIdBack(){
                this.newBack = null;
            },
        	initializeDocuments(clientInfo){
                this.front = null;
                this.back = null;
                this.others = [];
                if(clientInfo.document_front)
                    this.front = clientInfo.document_front;
                if(clientInfo.document_back)
                    this.back = clientInfo.document_back
                if(clientInfo.other_documents)
                    this.others = clientInfo.other_documents;
                console.log(clientInfo);
        	},
            resetErrors(){
                this.errors={

                };
            },
            clickOnDocument(selector){
                $(selector + ' input[type=file]').click()
            },
            calculateTotalSize(){
                var size = 0;
                for(var i = 0; i < this.documentsToAdd.length; i++){
                    size += this.documentsToAdd[i].size;
                }
                this.totalSize = size;
                if(this.newFront != null)
                    this.totalSize += this.newFront[i].size;
                if(this.newBack != null)
                    this.totalSize += this.newBack[i].size;
            },
            transformToHuman(total){
                var sizes = ['KB','MB','GB','TB'];
                var finalSize = "";
                for(var i = 0; i < sizes.length; i++){
                    total /= 1024;
                    if(total < 1024){
                        finalSize = total.toFixed(2) + ' ' + sizes[i];
                        return finalSize;
                    }
                }
            },
            isOverTotalDocuments(){
                var totalNew = this.documentsToAdd.length;
                var totalAlready = this.others.length;
                var total = totalNew + totalAlready;
                this.totalFiles = total;
                return total >= this.maxDocuments;
            },
            removeNewDocument(index){
                this.documentsToAdd.splice(index, 1);
            },
            removeDocument(documentToDelete, index){
                var vm=this;
                this.$swal({
                    title: "Atención",
                    text: "No podrás deshacer esta acción",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Sí',
                }).then((result) => {
                    if(result.value){
                        this.idDeleting = documentToDelete.id;
                        axios({
                            method: 'delete',
                            url: this.base+'/'+documentToDelete.id,
                            responseType: 'json',
                        }).then(function(response){
                            vm.$root.$emit('clients.updated');
                            vm.others.splice(index, 1);
                            var copyOfOthers = JSON.parse(JSON.stringify(vm.others));
                            vm.others = [];
                            Vue.nextTick(function(){
                                vm.others = copyOfOthers;
                            })
                            vm.idDeleting = null;
                        })
                        .catch(function (error) {
                            vm.$swal(
                              '¡Ups!',
                              'Parece que algo fue mal',
                              'error'
                            );  
                        });
                    }
                })
            },
            validate(){
                var validSize = this.totalSize < this.maxSize;
                var totalDocuments = 0;
                if(this.newFront!=null && this.newFront.length > 0) totalDocuments++;
                if(this.newBack!=null && this.newBack.length > 0) totalDocuments++;
                totalDocuments += this.documentsToAdd.length;

                var valid = validSize && totalDocuments > 0;

                if(!valid){
                    if(!validSize){
                        this.$swal(
                            'Atención',
                            'Estás intentando subir ficheros con un tamaño más grande del permitido',
                            'warning'
                        );
                    }
                    if(totalDocuments == 0){
                        this.$swal(
                            'Atención',
                            'Debes subir al menos un archivo',
                            'warning'
                        );
                    }
                }
                return valid;
            },
            prepareData(){
                var formData = new FormData();
                formData.append('id_client', this.client.id);
                if(this.newFront != null)
                    formData.append('front', this.newFront[0]);
                if(this.newBack != null)
                    formData.append('back', this.newBack[0]);
                if(this.documentsToAdd != null && this.documentsToAdd.length > 0){
                    for(var i = 0; i < this.documentsToAdd.length; i++){
                        formData.append('other[' + i + ']', this.documentsToAdd[i]);
                    }
                }
                return formData;
            },
            save(){
                if(!this.validate())
                    return;
                var vm=this;
                var url=this.base;
                var data = this.prepareData();
                this.saving=true;
                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    responseType: 'json',
                }).then(function(response){
                    vm.saving=false;
                    vm.$root.$emit('clients.updated');
                    vm.client = null;
                    vm.$swal(
                      'Correcto',
                      'Se ha guardado la documentación',
                      'success'
                    );
                    vm.exit();
                })
                .catch(function (error) {
                    vm.saving = false;
                    var message = 'Parece que algo fue mal';
                    if(error.response.status == 400)
                        message = error.response.data.message;
                    if(error.response.status == 404)
                        message = "El registro ya no existe";
                    vm.$swal(
                      '¡Ups!',
                      message,
                      'error'
                    );
                });
            },
            exit(){
                $('#clientDocumentsModal').modal('hide');
            },
            cancel(){
                var totalDocuments = 0;
                if(this.newFront != null && this.newFront.length > 0) totalDocuments++;
                if(this.newBack != null && this.newBack.length > 0) totalDocuments++;
                totalDocuments += this.documentsToAdd.length;
                if(totalDocuments > 0){
                    this.$swal({
                        title: "Atención",
                        text: "Tienes documentos sin subir, para que los cambios se guarden debes hacer clic en guardar, ¿Confirmas que deseas salir?",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Sí',
                    }).then((result) => {
                        if(result.value){
                            this.exit();
                        }
                    })
                }
            }
        }
    }
</script>
