<template>
	<div>
    	<div class="row">
			<div class="col-12 col-md">
            	<label>Lugar de fallecimiento * <a v-if="isAdmin" href="javascript:void(0)" v-on:click="addHospital()">añadir</a></label>
            	<autocomplete-component v-model="contract.id_hospital" v-bind:options="localPlaces" v-bind:key-code="'id'" v-bind:key-label="'name'"></autocomplete-component>
        	</div>
        	<div class="col-12 col-md" v-if="contract.id_hospital==0">
        		<label>Otro lugar</label>
        		<input type="text" class="form-control" placeholder="Otro lugar" v-model="contract.other_deceasing_place" v-bind:class="{'is-invalid':errors.other_deceasing_place}">
        	</div>
        	<div class="col-12 col-md">
            	<label>Localidad fallecimiento</label>
            	<input type="text" class="form-control" placeholder="Localidad fallecimiento" v-model="contract.deceasing_place" v-bind:class="{'is-invalid':errors.deceasing_place}">
        	</div>
        	<div class="col-12 col-md">
            	<label>Provincia</label>
            	<input type="text" class="form-control" placeholder="Provincia" v-model="contract.deceasing_state" v-bind:class="{'is-invalid':errors.deceasing_state}">
        	</div>
    	</div>
    	<div class="row">
    			<div class="col-12 col-md-3">
            	<label>Fecha</label>
            	<input-date v-model="contract.deceasing_date" placeholder="Fecha" input-class="form-control"></input-date>
        	</div>
        	<div class="col-12 col-md-3">
            	<label>Hora</label>
            	<input-time v-model="contract.deceasing_time" placeholder="Hora de fallecimiento" input-class="form-control"></input-time>
        	</div>
        	<div class="col-12 col-md-6">
    			<label>Causa inmediata</label>
    			<input type="text" class="form-control" placeholder="Causa inmediata" v-model="contract.deceasing_inmediate_cause" v-bind:class="{'is-invalid':errors.deceasing_inmediate_cause}">
        	</div>
    	</div>
    	<div class="row">
			<div class="col-12 col-md-6">
    			<label>Causa intermedia</label>
    			<input type="text" class="form-control" placeholder="Causa intermedia" v-model="contract.deceasing_intermediate_cause" v-bind:class="{'is-invalid':errors.deceasing_intermediate_cause}">
    		</div>
        	<div class="col-12 col-md-6">
    			<label>Causa fundamental</label>
    			<input type="text" class="form-control" placeholder="Causa fundamental" v-model="contract.deceasing_fundamental_cause" v-bind:class="{'is-invalid':errors.deceasing_fundamental_cause}">
        	</div>
        	<div class="col-12">
    			<label>Otros procesos</label>
    			<input type="text" class="form-control" placeholder="Otros procesos" v-model="contract.deceasing_other_processes" v-bind:class="{'is-invalid':errors.deceasing_other_processes}">
    		</div>
    	</div>
    	<div class="row">
			<div class="col-12 col-md">
    			<label>Nº certificado</label>
    			<input type="text" class="form-control" placeholder="Nº certificado" v-model="contract.number_certificate" v-bind:class="{'is-invalid':errors.number_certificate}">
        	</div>
        	<div class="col-12 col-md">
    			<label>Nº colegiado</label>
    			<input type="text" class="form-control" placeholder="Nº colegiado" v-model="contract.college_number" v-bind:class="{'is-invalid':errors.college_number}">
        	</div>
        	<div class="col-12 col-md">
    			<label>Provincia colegiado</label>
    			<input type="text" class="form-control" placeholder="Provincia colegiado" v-model="contract.college_state" v-bind:class="{'is-invalid':errors.college_state}">
        	</div>
        	<div class="col-12 col-md-6">
    			<label>Nombre colegiado</label>
    			<input type="text" class="form-control" placeholder="Nombre colegiado" v-model="contract.college_name" v-bind:class="{'is-invalid':errors.college_name}">
        	</div>
    	</div>
		<div class="row">
			<div class="col-12 col-md-4">
    			<label>Diligencias previas</label>
    			<input type="text" class="form-control" placeholder="Diligencias previas" v-model="contract.previous_diligences" v-bind:class="{'is-invalid':errors.previous_diligences}">
        	</div>
        	<div class="col-12 col-md-8">
    			<label>Nº de juzgado</label>
    			<input type="text" class="form-control" placeholder="Nº de juzgado" v-model="contract.judge_number" v-bind:class="{'is-invalid':errors.judge_number}">
        	</div>
		</div>
	</div>
</template>
<script>
	import Datepicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import 'vue2-datepicker/locale/es';
	export default{
		components: { Datepicker },
		props: ['contract', 'hospitals'],
		mounted(){
			this.initialize();
			this.$root.$on('hospitals.updated', this.addNewHospital);
		},
		data(){
			return {
				deadDate: null,
				deadTime: null,
				placesLodaded: false,
				isAdmin: user_role == 'admin',
				localPlaces: [
					{
						id: 0,
						name: 'Otros lugares'
					},
					{
						id: 1000,
						name: 'Domicilio'
					},
				],
				lang: {
            		formatLocale: {
              			firstDayOfWeek: 1,
            		},
                	monthBeforeYear: false,
            	},
	            errors:{

	            }
			}
		},
		methods: {
			initialize(){
				this.deadDate = this.contract.deceasing_date != null? new Date(this.contract.deceasing_date): null;
				this.deadTime = this.contract.deceasing_time != null? new Date("1970-01-01 " + this.contract.deceasing_time): null;
				this.initializePlaces();
			},
			initializePlaces(){
				var hospitals = this.hospitals;
				for(var i in hospitals){
					this.localPlaces.push({
						id: hospitals[i].id,
						name: hospitals[i].name,
					});
				}
			},
			addNewHospital(hospital){
				this.localPlaces.push({
					id: hospital.id,
					name: hospital.name,
				});
			},
			changeDead(evt){
				if(evt != null) this.contract.deceasing_date = moment(evt).format('YYYY-MM-DD');
	            else this.contract.deceasing_date = null;
			},
			changeDeadTime(evt){
				if(evt != null) this.contract.deceasing_time = moment(evt).format('HH:mm:ss');
	            else this.contract.deceasing_time = null;
			},
			addHospital(){
				this.$root.$emit('hospitals.add');
			}

		}
	}
</script>