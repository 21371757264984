<template>
	<div class="modal fade action-sheet opax" id="budgetContactModal" tabindex="-1" role="dialog">
	    <div class="modal-dialog modal-xl" role="document">
	    	<div class="modal-content">
			    <div class="modal-header">
				    <h5 class="modal-title">Enviar presupuesto</h5>
				    <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-on:click="cancel();">
				    <span aria-hidden="true">&times;</span>
				    </button>
				</div>
				<div class="modal-body">
                    <div class="row">
                        <div class="form-group col-12 col-md">
                        	<label>Concepto</label>
                        	<input type="text" v-model="newRow.concept" class="form-control" placeholder="Concepto">
                        </div>
                        <div class="form-group col-12 col-md">
                        	<label>Precio</label>
                        	<input type="number" v-model="newRow.base" class="form-control" placeholder="Precio">
                        </div>
                        <div class="form-group col-12 col-md">
                        	<label>Unidades</label>
                        	<input type="number" v-model="newRow.units" class="form-control" placeholder="Unidades">
                        </div>
                        <div class="form-group col-12 col-md">
                        	<label>% IVA</label>
                        	<input type="number" v-model="newRow.p_iva" min="0" max="100" class="form-control" placeholder="% IVA">
                        </div>
                        <div class="form-group col-12 col-md">
                        	<label>% Descuento</label>
                        	<input type="number" v-model="newRow.p_discount" min="0" max="100" class="form-control" placeholder="% Descuento">
                        </div>
                        <div class="form-group col-12 col-md d-flex">
                        	<button class="btn btn-success ml-auto mt-auto" v-on:click="addRow()">Añadir</button>
                        </div>
                    </div>
                    <div class="table-responsive" v-if="rows.length > 0">
                    	<table class="table">
                    		<thead>
                    			<tr>
                    				<th></th>
                    				<th>Concepto</th>
                    				<th>Base</th>
                    				<th>Uds</th>
                    				<th>% IVA</th>
                    				<th>% Desc.</th>
                    				<th>IVA</th>
                    				<th>Desc.</th>
                    				<th class="text-right">Total</th>
                    			</tr>
                    		</thead>
                    		<tbody>
                    			<tr v-for="(r, index) in rows">
                    				<td>
                    					<button class="btn btn-danger btn-sm" v-on:click="removeRow(index)">Quitar</button>
                    				</td>
                    				<th>
                    					{{ r.concept }}
                    				</th>
                    				<td>
                    					{{ r.base|round }}
                    				</td>
                    				<td>
                    					{{ r.units }}
                    				</td>
                    				<td>
                    					{{ r.p_iva }}
                    				</td>
                    				<td>
                    					{{ r.p_discount }}
                    				</td>
                    				<td>
                    					{{ getIva(r)|round }}
                    				</td>
                    				<td>
                    					{{ getDiscount(r)|round }}
                    				</td>
                    				<td align="right">
                    					{{ getTotal(r)|round }}
                    				</td>
                    			</tr>
                    		</tbody>
                    		<tfoot>
                    			<tr>
                    				<th colspan="6" align="right" class="text-right">
                    					Total
                    				</th>
                    				<th>
                    					{{ getTotalIva()|round }}
                    				</th>
                    				<th>
                    					{{ getTotalDiscount()|round }}
                    				</th>
                    				<th align="right"  class="text-right">
                    					{{ getTotalBudget()|round }}
                    				</th>
                    			</tr>
                    		</tfoot>
                    	</table>
                    </div>
				</div>
				<div class="modal-footer">
                    <button type="button" class="btn btn-success" v-on:click="send();">Enviar</button>
				    <button type="button" class="btn btn-danger" data-dismiss="modal" v-on:click="cancel();">Cerrar</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
    export default {
        mounted() {
            this.$root.$on('contacts.budget.edit',this.openModal);
        },
        filters: {
        	round(input){
        		if(input)
        			return parseFloat(input).toFixed(2);
        		return "0.00";
        	}
        },
        data(){
            return {
            	base: window.urls['contacts.budget.index'],
                rows:[],
                newRow: {
                	concept: null,
                	units: 1,
                	base: null,
                	p_iva: 21,
                	p_discount: 0
                },
                saving: false,
                contact: null,
            }
        },
        methods:{
        	openModal(data){
        		this.contact = data;
        		this.rows = [];
        		this.resetNewRow();
        		$('#budgetContactModal').modal({'backdrop':'static'});
        	},
        	resetNewRow(){
        		this.newRow = {
                	concept: null,
                	units: 1,
                	base: null,
                	p_iva: 21,
                	p_discount: 0
                };
        	},
        	getIva(row){
        		return row.units * row.base * row.p_iva / 100;
        	},
        	getDiscount(row){
        		return row.units * row.base * row.p_discount / 100;
        	},
        	getTotal(row){
        		var iva = this.getIva(row);
        		var discount = this.getDiscount(row);
        		return row.units * row.base + iva + discount;
        	},

        	getTotalIva(){
        		var total = 0;
        		for(var i in this.rows){
        			total += this.getIva(this.rows[i])
        		}
        		return total;
        	},
            getTotalDiscount(){
            	var total = 0;
        		for(var i in this.rows){
        			total += this.getDiscount(this.rows[i])
        		}
        		return total;
            },
            getTotalBudget(){
            	var total = 0;
        		for(var i in this.rows){
        			total += this.getTotal(this.rows[i]);
        		}
        		return total;
            },
        	addRow(){
        		if(!this.newRow.concept || !this.newRow.base)
        			return ;
        		this.rows.push(this.newRow);
        		this.resetNewRow();
        	},
        	removeRow(index){
        		this.rows.splice(index, 1);
        	},
        	send(){
                if(this.rows.length == 0)
                    return;
                var url = this.base;
                var vm = this;
                this.saving=true;
                axios({
                    method: 'post',
                    url: url,
                    data: {
                    	contact: this.contact,
                    	rows: this.rows
                    },
                    responseType: 'json',
                }).then(function(response){
                    vm.saving=false;
                    vm.$root.$emit('contacts.updated');
                    vm.cancel();
                    vm.$swal(
                      'Correcto',
                      'Se ha guardado el presupuesto',
                      'success'
                    );
                })
                .catch(function (error) {
                    vm.saving = false;
                    var message = 'Parece que algo fue mal';
                    if(error.response.status == 400)
                        message = error.response.data.message;
                    if(error.response.status == 404)
                        message = "El registro ya no existe";
                    vm.$swal(
                      '¡Ups!',
                      message,
                      'error'
                    );
                });
        	},
            cancel(){
            	$('#budgetContactModal').modal('hide');
            }
        }
    }
</script>
