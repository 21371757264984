<template>
	<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-body">
					<VueSignaturePad class="form-control" width="100%" height="300px" ref="signaturePad" :options="{ onBegin, onEnd }"/>
                </div>
                <div class="modal-footer">
                	<button v-if="signed" type="button" v-on:click="clear()" class="mt-3 btn btn-md btn-secondary">Limpiar firma</button>
                	<div class="ml-auto">
                    	<button type="button" class="btn btn-danger" data-dismiss="modal">Cerrar</button>
                    	<button type="button" class="btn btn-success" v-bind:disabled="!signed || saving" v-on:click="sign()">Confirmar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
	export default{
		props: ['id'],
		data(){
            return {
            	base: window.urls['contracts.clients.budget.index'],
                signed: false,
                signdata:null,
                resized:false,
                saving: false
            }
        },
		methods: {
			onBegin(){
				this.$refs.signaturePad.resizeCanvas();
        	},
        	onEnd(){
        		this.getSignature();
        	},
        	getSignature() {
		      	const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
		      	this.signed = !isEmpty;
		      	this.signdata = data;
		  	},
        	clear(){
        		this.$refs.signaturePad.clearSignature();
        		this.signdata = null;
    			this.signed = false;
        	},
        	sign(){
        		var url = this.base + '/sign/' + this.id;
                var vm = this;
                this.saving = true;
                axios({
                    method: 'post',
                    url: url,
                    data: {
                    	sign: this.signdata
                    },
                    responseType: 'json',
                }).then(function(response){
                    vm.saving = false;
                    var bc = new BroadcastChannel('sign.budget.channel');
                    bc.postMessage(response.data);
                    location.reload();
                })
                .catch(function (error) {
                    vm.saving = false;
                    var message = 'Parece que algo fue mal';
                    if(error.response.status == 400)
                        message = error.response.data.message;
                    if(error.response.status == 404)
                        message = "El registro ya no existe";
                    vm.$swal(
                      '¡Ups!',
                      message,
                      'error'
                    );
                });
        	},
		}
	}
</script>