<template>
	<div class="modal fade action-sheet opax" id="portfolioModal" tabindex="-1" role="dialog">
	    <div class="modal-dialog modal-md" role="document">
	    	<div class="modal-content">
			    <div class="modal-header">
				    <h5 class="modal-title">{{ portfolio.id?'Editar ':'Nuevo '}} {{ title }}</h5>
				    <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-on:click="cancel();">
				    <span aria-hidden="true">&times;</span>
				    </button>
				</div>
				<div class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            <label>Nombre *</label>
                            <input spellcheck="true" type="text" class="form-control" v-model="portfolio.name"  placeholder="Nombre" v-bind:class="{'is-invalid':errors.name}"/>
                        </div>
                        <div class="col-12">
                            <label>Código</label>
                            <input spellcheck="true" type="text" class="form-control" v-model="portfolio.code"  placeholder="Código" v-bind:class="{'is-invalid':errors.code}"/>
                        </div>
                        <div class="col-12">
                            <label>Categoría *</label>
                            <select type="text" class="form-control" v-model="portfolio.id_category" v-bind:class="{'is-invalid':errors.id_category}">
                                <option v-for="c in categories" v-bind:value="c.id">{{ c.label }}</option>
                            </select>
                        </div>
                        <div class="col-12">
                            <label>Activo *</label>
                            <select type="text" class="form-control" v-model="portfolio.active" v-bind:class="{'is-invalid':errors.active}">
                                <option value="1">Sí</option>
                                <option value="0">No</option>
                            </select>
                        </div>
                        <div class="col-12">
                            <label>Precio *</label>
                            <input spellcheck="true" type="number" min="0" step="0.01" class="form-control" v-model="portfolio.price"  placeholder="Precio" v-bind:class="{'is-invalid':errors.price}" v-on:change="calculateTotal()"/>
                        </div>
                        <div class="col-12">
                            <label>Impuestos (%) *</label>
                            <input spellcheck="true" type="number" min="0" max="100" step="1" class="form-control" v-model="portfolio.taxes" placeholder="Precio" v-bind:class="{'is-invalid':errors.taxes}" v-on:change="calculateTotal()"/>
                        </div>
                        <div class="col-12">
                            <label>Total</label>
                            <input type="number" class="form-control" v-model="portfolio.total" placeholder="Contraseña" v-bind:class="{'is-invalid':errors.total}" v-on:change="calculatePrice()" />
                        </div>
                    </div>
				</div>
				<div class="modal-footer">
				    <button type="button" class="btn btn-success mr-2" v-on:click="save();" v-bind:disabled="saving"><i class="fa fa-spin fa-spinner" v-show="saving"></i> Guardar</button>
				    <button type="button" class="btn btn-danger" data-dismiss="modal" v-on:click="cancel();">Cancelar</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
    export default {
        mounted() {
            this.$root.$on('portfolio.add',this.openModal);
            this.$root.$on('portfolio.edit',this.openModal);
            this.title = this.getTitle();
            this.getCategories();
        },
        beforeMount(){
            let uri = window.location.search.substring(1); 
            let params = new URLSearchParams(uri);
            let type = params.get("type")
            this.type = params.get("type");
        },
        data(){
            return {
            	base: window.urls['portfolio.index'],
                baseCategories: window.urls['portfolio.categories.index'],
                categories:[],
                saving: false,
                type: 'service',
                title: 'Servicio',
                portfolio:{
                    id_category: null,
                    type: null,
                    active: '1',
                    name: null,
                    price: null,
                    taxes: null,
                    total: null,
                    code: null
                },
                errors: {
                    id_category: false,
                    type: false,
                    active: false,
                    name: false,
                    price: false,
                    taxes: false,
                    total: false,
                },
            }
        },
        methods:{
            getTitle(){
                switch(this.type){
                    case 'product': return 'producto'; break;
                    case 'service': return 'servicio'; break;
                    case 'supplied': return 'suplido'; break;
                }
            },
            getCategories(){
                var vm=this;
                var type = {
                    type: this.type
                };
                axios({
                    method: 'get',
                    url: this.baseCategories + '/table?query=' + JSON.stringify(type) + '&orderBy=label&ascending=1',
                    responseType: 'json',
                }).then(function(response){
                    vm.categories=response.data.data;
                })
                .catch(function (error) {
                    vm.getting=false;
                    vm.$swal(
                      '¡Ups!',
                      'Parece que algo fue mal',
                      'error'
                    );  
                }); 
            },
            checkIfNumber(evt){
                var charCode = (evt.which) ? evt.which : evt.keyCode;
                if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                    evt.preventDefault();;
                } else {
                    return true;
                }
            },
            calculateTotal(){
                var price = this.portfolio.price?parseFloat(this.portfolio.price):0;
                var taxes = this.portfolio.taxes?parseFloat(this.portfolio.taxes):0;
                var total = price * (1 + taxes/100);
                this.portfolio.total = this.roundTwoDecimals(total);
            },
            calculatePrice(){
                var total = this.portfolio.total?parseFloat(this.portfolio.total):0;
                var taxes = this.portfolio.taxes?parseFloat(this.portfolio.taxes):0;
                var price = total / (1 + taxes/100);
                this.portfolio.price = this.roundTwoDecimals(price);
            },
            roundTwoDecimals(input){
                var aux = input * 100;
                return Math.round(aux) / 100;
            },
        	openModal(data){
                if(data){
                    this.portfolio={
                        id: data.id,
                        id_category: data.id_category,
                        type: data.type,
                        active: data.active,
                        name: data.name,
                        price: data.price,
                        taxes: data.taxes,
                        total: data.total,
                        code: data.code
                    };
                }else{
                    this.portfolio={
                        id_category: null,
                        type: this.type,
                        active: '1',
                        name: null,
                        price: null,
                        taxes: null,
                        total: null,
                        code: null
                    };
                }
                this.resetErrors();
        		$('#portfolioModal').modal({'backdrop':'static'});
        	},
            resetErrors(){
                this.errors={
                    id_category: false,
                    type: false,
                    active: false,
                    name: false,
                    price: false,
                    taxes: false,
                    total: false,
                    code: false
                };
            },
            validate(){
                var valid = true;
                this.errors.id_category = this.portfolio.id_category == null || this.portfolio.id_category == "";
                this.errors.type = this.portfolio.type == null || this.portfolio.type == "";
                this.errors.active = this.portfolio.active == null || this.portfolio.active == "";
                this.errors.name = this.portfolio.name == null || this.portfolio.name == "";
                this.errors.price = this.portfolio.price == null || this.portfolio.price == "";
                this.errors.taxes = this.portfolio.taxes == null || this.portfolio.taxes == "";
                this.errors.total = this.portfolio.total == null || this.portfolio.total == "";

                for(var i in this.errors)
                    valid &= !this.errors[i];
                if(!valid)
                    this.showRequiredMessage();
                return valid;
            },
            showRequiredMessage(){
                this.$swal(
                    'Atención',
                    'Hay datos obligatorios por rellenar',
                    'warning'
                );
            },
            save(){
                if(!this.validate())
                    return;
                var url=this.base;
                var vm=this;
                if(this.portfolio.id) url += '/' + this.portfolio.id;
                this.saving=true;
                axios({
                    method: 'post',
                    url: url,
                    data: this.portfolio,
                    responseType: 'json',
                }).then(function(response){
                    vm.saving=false;
                    vm.$root.$emit('portfolio.updated');
                    vm.cancel();
                    vm.$swal(
                      'Correcto',
                      'Se ha guardado el ' + vm.title,
                      'success'
                    );
                })
                .catch(function (error) {
                    vm.saving = false;
                    var message = 'Parece que algo fue mal';
                    if(error.response.status == 400)
                        message = error.response.data.message;
                    if(error.response.status == 404)
                        message = "El registro ya no existe";
                    vm.$swal(
                      '¡Ups!',
                      message,
                      'error'
                    );
                });
            },
            cancel(){
            	$('#portfolioModal').modal('hide');
            }
        }
    }
</script>
