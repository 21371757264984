<template>
	<div class="custom-input-wrapper">
		<div class="custom-input" onclick="$(this).parent().children('input').click()" v-bind:style="getStyle()">
	  	</div>
	  	<input class="d-none" type="file" v-bind:accept="accept" v-on:change="loadFile" />
	  	<div class="row" v-if="fileUrl">
	  		<div class="col-6 text-left">
	  			<a target="_blank" v-bind:href="fileUrl"><i class="fa fa-eye"></i></a>
	  		</div>
	  		<div class="col-6 text-right" v-if="changed">
	  			<a href="javascript:void(0)" class="text-danger" v-on:click="removeFile" ><i class="fa fa-times"></i></a>
	  		</div>
	  	</div>
	</div>
</template>

<script>
	export default {
	  	props: {
		    value: {
				required: true
		    },
		    accept: null,
		    initial: null
		},
		mounted(){
			this.$root.$on('contracts.edit',this.resetComponent);
		},
		data(){
			return {
				changed: false,
				imageSrc: null,
				fileUrl: null,
				file: null,
				isImage: false,
	  			isPdf: false
			}
		},
	  	methods:{
	  		resetComponent(){
	  			this.changed = false;
	  		},
	  		loadFile($event){
				const files = $event.target.files || $event.dataTransfer.files
				var aux=[];
				for(var i=0;i<files.length;i++){
					aux.push(files[i]);
				}
				this.changed = true;
				this.file = aux[0];
				this.$emit('input', aux[0]);
				this.updateThisComponent();
	  		},
	  		getStyle(){
	  			if(!this.changed && this.initial){
	  				if(this.initial.indexOf('.pdf') == -1)
	  					this.imageSrc = this.initial
	  				else
	  					this.imageSrc = '/images/pdficon.png';
	  				this.fileUrl = this.initial;
	  				return "background-image: url('" + this.imageSrc + " ')";
	  			}
	  			if(this.fileUrl){
	  				return "background-image: url('" + this.imageSrc + " ')";
	  			}
	  			return ;
	  		},
	  		updateThisComponent(){
	  			this.fileUrl = URL.createObjectURL(this.file);
		  		this.loadImage();
	  		},
	  		removeFile(){
	  			this.file = null;
	  			this.fileUrl = null;
	  			this.imageSrc = null;
	  			this.$emit('input', this.file);
	  		},
	  		loadImage(){
	  			this.isPdf = this.file.type == "application/pdf";
	  			this.isImage = this.file.type.indexOf("image/") != -1;
	  			if(this.isImage)
	  				this.imageSrc = this.fileUrl;
	  			else
	  				this.imageSrc = '/images/pdficon.png';
	  		}
	  	}
	}
</script>