<template>
	<div class="modal fade action-sheet opax" id="rolesPermissionsModal" tabindex="-1" role="dialog">
	    <div class="modal-dialog modal-md" role="document">
	    	<div class="modal-content">
			    <div class="modal-header">
				    <h5 class="modal-title">Control de accesos</h5>
				    <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-on:click="cancel();">
				    <span aria-hidden="true">&times;</span>
				    </button>
				</div>
				<div class="modal-body">
                    <div class="row mb-1" v-for="p in permissions">
                        <div class="col-9">
                            <b>
                                {{ labels[p.section] }}
                            </b>
                        </div>
                        <div class="col-3 text-right">
                            <button class="btn btn-sm" v-bind:class="{'btn-success':p.access,'btn-danger':!p.access}" v-on:click="togglePermission(p)">
                                {{ p.access?'Habilitado':'Deshabilitado' }}
                            </button>
                            
                        </div>
                    </div>
				</div>
				<div class="modal-footer">
				    <button type="button" class="btn btn-danger" data-dismiss="modal" v-on:click="cancel();">Cerrar</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
    export default {
        mounted() {
            this.$root.$on('roles.edit.permissions',this.openModal);
        },
        data(){
            return {
            	base: window.urls['roles-permissions.index'],
                permissions:[],
                labels:{
                    'contracts.index': 'Expedientes',
                    'clients.index': 'Clientes',
                    'users.index': 'Comerciales',
                    'portfolio.index': 'Porfolio',
                    'contacts.index': 'Contactos',
                    'roles.index': 'Perfiles',
                    'hospitals.index': 'Hospitales',
                    'portfolio.categories.index': 'Categorías',
                    'arks.index': 'Arcas',
                    'vehicles.index': 'Vehículos',
                    'cementeries.index': 'Cementerios',
                    'bankaccounts.index': 'Números de cuenta',
                    'installations.index': 'Instalaciones'
                }
            }
        },
        methods:{
        	openModal(data){
                this.permissions = data.permissions;
                for(var i = 0; i < this.permissions; i++)
                    this.permissions[i].saving = false;
        		$('#rolesPermissionsModal').modal({'backdrop':'static'});
        	},
            togglePermission(permission){
                permission.access = !permission.access;
                this.save(permission);
            },
            save(permission){
                var url = this.base +  '/' + permission.id;;
                var vm = this;
                permission.saving = true;
                axios({
                    method: 'post',
                    url: url,
                    data: permission,
                    responseType: 'json',
                }).then(function(response){
                    permission.saving = false;
                    vm.$root.$emit('roles.updated');
                    
                })
                .catch(function (error) {
                    permission.saving = false;
                    var message = 'Parece que algo fue mal';
                    if(error.response.status == 400)
                        message = error.response.data.message;
                    if(error.response.status == 404)
                        message = "El registro ya no existe";
                    vm.$swal(
                      '¡Ups!',
                      message,
                      'error'
                    );
                });
            },
            cancel(){
            	$('#rolesPermissionsModal').modal('hide');
            }
        }
    }
</script>
