<template>
	<div class="modal fade action-sheet opax" id="changeCommercialModal" tabindex="-1" role="dialog">
	    <div class="modal-dialog modal-sm" role="document">
	    	<div class="modal-content">
			    <div class="modal-header">
				    <h5 class="modal-title">Editar comercial</h5>
				    <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-on:click="cancel();">
				    <span aria-hidden="true">&times;</span>
				    </button>
				</div>
				<div class="modal-body" v-if="contract">
					<div class="row">
						<div class="form-group col-12">
							<label>Comercial</label>
							<select class="form-control" v-model="contract.id_commercial">
								<option v-for="c in commercials" v-bind:value="c.id">{{ c.name }} {{ c.lastname }}</option>
							</select>
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-success mr-2" v-on:click="save();" v-bind:disabled="saving"><i class="fa fa-spin fa-spinner" v-show="saving"></i> Guardar</button>
					<button type="button" class="btn btn-danger" data-dismiss="modal" v-on:click="cancel();">Cancelar</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default{
		mounted() {
			this.$root.$on('contracts.edit.commercial',this.openModal);
			this.getContext();
		},
		data(){
			return {
				base: window.urls['contracts.index'],
				baseCommercials: window.urls['users.index'],
				commercials: [],
				saving: false,
				contract: null
			};
		},
		methods:{
			getContext(){
				var vm = this;
                var filter = {
                    id_role: 2
                };
                axios({
                    method: 'get',
                    url: this.baseCommercials + '/table?query=' + JSON.stringify(filter) + '&orderBy=name&ascending=1',
                    responseType: 'json',
                }).then(function(response){
                    vm.commercials = response.data.data;
                })
                .catch(function (error) {
                    vm.getting=false;
                    vm.$swal(
                      '¡Ups!',
                      'Parece que algo fue mal',
                      'error'
                    );  
                }); 
			},
			openModal(data){
				this.contract = JSON.parse(JSON.stringify(data));
				$('#changeCommercialModal').modal({'backdrop':'static'});
			},
			save(){
				var vm = this;
				var url = this.base + '/' + this.contract.id;
				this.saving = true;
				axios({
	                method: 'post',
	                url: url,
	                data: {
	                    contract: {
	                    	id: this.contract.id,
	                    	id_commercial: this.contract.id_commercial
	                    }
	                },
	                responseType: 'json',
	            }).then(function(response){
	                vm.saving=false;
	                vm.cancel();
	                vm.$root.$emit('contracts.updated');
	            })
			},
			cancel(){
            	$('#changeCommercialModal').modal('hide');
            }
		}
	}
</script>