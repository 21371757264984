<template>
	<div>
	    <div class="row">
	        <div class="col-12 col-md-8">
	            <label>Tipo de carpeta</label>
	            <select type="text" class="form-control" v-model="contract.type_of_folder" v-bind:class="{'is-invalid':errors.type_of_folder}">
                    <option value="expedient">Expediente</option>
                    <option value="sickness">Enferetramiento</option>
                    <option value="precontract">Precontratado</option>
                    <option value="budget">Presupuesto</option>
                </select>
	        </div>
	        <div class="col-12 col-md-4">
	            <label>Comercial</label>
	            <select type="text" class="form-control" v-model="contract.id_commercial" v-bind:class="{'is-invalid':errors.id_commercial}">
                    <option v-for="c in commercials" v-bind:value="c.id">{{ c.name }}</option>
                </select>
	        </div>
	    </div>
	    <div class="row">
	        <div class="col-12 col-md">
	            <label>Tipo de cliente</label>
	            <select class="form-control" v-model="contract.service_type_of_client" v-bind:class="{'is-invalid':errors.service_type_of_client}">
	            	<option value="particular">Particular</option>
                    <option value="company">Empresa</option>
                    <option value="insurance">Aseguradora</option>
	            </select>
	        </div>
	        <div class="col-12 col-md" v-if="contract.service_type_of_client == 'insurance'">
	            <label>Nombre aseguradora</label>
	            <input type="text" class="form-control" v-model="contract.insurance_name" v-bind:class="{'is-invalid':errors.insurance_name}" />
	        </div>
	        <div class="col-12 col-md" v-if="contract.service_type_of_client == 'insurance'">
	            <label>Pago de Aseguradora</label>
	            <input type="number" class="form-control" v-model="contract.insurance_payment" />
	        </div>			
	
	        <div class="col-12 col-md">
	            <label>Tipo de servicio</label>
	            <select class="form-control" v-model="contract.service_type" v-bind:class="{'is-invalid':errors.service_type}">
	            	<option value="full">Servicio funebre completo</option>
                    <option value="sickness">Enferetramiento</option>
                    <option value="reception">Servicio de recepción</option>
                    <option value="wake">Velatorio</option>
                    <option value="mortuary">Prestaciones funerarias</option>
                    <option value="others">Otros</option>
	            </select>
	        </div>
	        <div class="col-12 col-md">
	            <label>Empresa traslado</label>
	            <select type="text" class="form-control" v-model="contract.service_delivery_company" v-bind:class="{'is-invalid':errors.service_delivery_company}">
                    <option v-for="c in companies" v-bind:value="c.id">{{ c.name }}</option>
                    <option value="0">Otra</option>
                </select>
	        </div>
	        <div class="col-12 col-md" v-if="contract.service_delivery_company == 0">
	            <label>Empresa</label>
	            <input type="text" class="form-control" v-model="contract.other_service_delivery_company" v-bind:class="{'is-invalid':errors.other_service_delivery_company}">
	        </div>
        </div>
	    <div class="row">
	        <div class="col-12 col-md">
	            <label>Empresa enferetramiento <a href="javascript:void(0)" v-on:click="addCoffining()">añadir</a></label>
	            <autocomplete-component v-model="contract.service_id_coffining" v-bind:options="coffinings" v-bind:key-code="'id'" v-bind:key-label="'tradename'"></autocomplete-component>
	        </div>
	        <div class="col-12 col-md">
	        	<label>Tanatorio destino</label>
        		<input type="text" class="form-control" v-model="contract.service_destination_tanatory" placeholder="Tanatorio de destino">
	        </div>
			<div class="col-12 col-md">
	        	<label>Pago derechos de Enterramiento</label>
        		<input type="number" class="form-control" v-model="contract.service_payment_cemetery" placeholder="Derechos de enterramiento">
	        </div>
	    </div>
	    <div class="row">
    		<!--<div class="col-12 col-md-6">
        		<label>Instalación intermedia</label>
        		<input type="text" class="form-control" v-model="contract.service_intermediate_instalation" v-bind:class="{'is-invalid':errors.service_intermediate_instalation}">
	        </div>-->
	        <div class="col-12 col-md-6">
        		<label>Localidad</label>
        		<input type="text" class="form-control" v-model="contract.service_intermediate_instalation_location" v-bind:class="{'is-invalid':errors.service_intermediate_instalation_location}">
        	</div>
	    </div>
	    <div class="row">
    		<div class="col-12 col-md-6">
        		<label>Sala prevista</label>
        		<input type="text" class="form-control" v-model="contract.service_estimated" v-bind:class="{'is-invalid':errors.service_estimated}">
	        </div>
	        <div class="col-12 col-md-6">
        		<label>Cámara prevista</label>
        		<input type="text" class="form-control" v-model="contract.service_chamber_estimated" v-bind:class="{'is-invalid':errors.service_chamber_estimated}">
	        </div>
	    </div>
	    <div class="row">
	    	<div class="col-12 col-md-3">
	            <label>Fecha entrada sala</label>
	            <datepicker v-model="dateEntrance" style="display: block;width: 100%"  v-bind:lang="lang" format="DD/MM/YYYY" type="date" placeholder="Fecha entrada sala" v-on:change="changeEntrance" v-bind:input-class="{'form-control is-invalid':errors.service_chamber_date_entrance_estimated,'form-control':!errors.service_chamber_date_entrance_estimated}"></datepicker>
	        </div>
	        <div class="col-12 col-md-3">
	            <label>Hora entrada sala</label>
	            <datepicker v-model="timeEntrance" style="display: block;width: 100%"  v-bind:lang="lang" format="HH:mm" type="time" placeholder="Hora entrada sala" v-on:change="changeEntranceTime" v-bind:input-class="{'form-control is-invalid':errors.service_chamber_time_entrance_estimated,'form-control':!errors.service_chamber_time_entrance_estimated}"></datepicker>
	        </div>
	        <div class="col-12 col-md-3" v-if="dateEntrance">
	            <label>Fecha salida sala</label>
	            <datepicker v-model="dateExit" style="display: block;width: 100%"  v-bind:lang="lang" format="DD/MM/YYYY" type="date" placeholder="Fecha salida sala" v-on:change="changeExit" v-bind:input-class="{'form-control is-invalid':errors.service_chamber_date_exit_estimated,'form-control':!errors.service_chamber_date_exit_estimated}"></datepicker>
	        </div>
	        <div class="col-12 col-md-3" v-if="timeEntrance">
	            <label>Hora salida sala</label>
	            <datepicker v-model="timeExit" style="display: block;width: 100%"  v-bind:lang="lang" format="HH:mm" type="time" placeholder="Hora salida sala" v-on:change="changeExitTime" v-bind:input-class="{'form-control is-invalid':errors.service_chamber_time_exit_estimated,'form-control':!errors.service_chamber_time_exit_estimated}"></datepicker>
	        </div>
	    </div>
	    <hr />
		<legend>Adornos florales</legend>
		<button class="btn btn-info" type="button" v-on:click="addOrnament()">Añadir</button>
		<button class="ml-3 btn btn-success" type="button" v-if="floral && floral.length > 0" v-on:click="makeOrder()" v-bind:disabled="ordering">Realizar pedido</button>
	    <div class="table-responsive mt-3">
	    	<table class="table">
		    	<thead>
		    		<tr>
		    			<th>Adorno</th>
		    			<th>Observaciones</th>
		    			<th>Texto</th>
		    			<th></th>
		    		</tr>
		    		<tr v-for="(o, index) in floral">
		    			<td>{{ o.ornament?o.ornament.name:'' }}</td>
		    			<td>{{ o.observations }}</td>
		    			<td>{{ o.text }}</td>
		    			<td class="text-right">
		    				 <button class="btn btn-sm btn-info" v-on:click="editOrnament(o, index)"><i class="fas fa-edit"></i></button>
		    				 <button class="btn btn-sm btn-danger" v-on:click="removeOrnament(o, index)"><i class="fas fa-trash"></i></button>
		    			</td>
		    		</tr>
		    	</thead>
		    </table>
	    </div>
	    <hr />
		<legend>Recordatorios</legend>
		<button class="btn btn-info" type="button" v-on:click="addReminder()">Añadir</button>
		<button class="ml-3 btn btn-success" type="button" v-if="reminders && reminders.length > 0" v-on:click="makeReminderOrder()" v-bind:disabled="orderingReminder">Realizar pedido</button>
		<div class="table-responsive mt-3">
	    	<table class="table">
		    	<thead>
		    		<tr>
		    			<th>Texto familiar</th>
		    			<th></th>
		    		</tr>
		    		<tr v-for="(r, index) in reminders">
		    			<td>{{ r.text }}</td>
		    			<td class="text-right">
		    				 <button class="btn btn-sm btn-info" v-on:click="editReminder(r, index)"><i class="fas fa-edit"></i></button>
		    				 <button class="btn btn-sm btn-danger" v-on:click="removeReminder(r, index)"><i class="fas fa-trash"></i></button>
		    			</td>
		    		</tr>
		    	</thead>
		    </table>
	    </div>
	</div>
</template>
<script>
	import Datepicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import 'vue2-datepicker/locale/es';
	export default{
		components: { Datepicker },
		props: [
			'contract',
			'commercials',
			'companies',
			'ornaments',
			'coffinings',
			'ornaments',
			'reminders',
			'providers',
			'floral'
		],
		mounted(){
			this.initialize();
			this.$root.$emit('contract-floral-ornaments.context', {
				ornaments: this.ornaments,
				providers: this.providers
			});
			this.$root.$on('contract-floral-ornaments.updated',this.updateOrnaments);
			this.$root.$on('contract-reminders.updated',this.updateReminders);
		},
		data(){
			return {
				baseContractOrnaments: window.urls['contract.floral-ornaments.index'],
				baseContractReminders: window.urls['contract.reminders.index'],
				dateEntrance: null,
				timeEntrance: null,
				dateExit: null,
				timeExit: null,
				isAdmin: user_role == 'admin',
				ordering: false,
				orderingReminder: false,
				lang: {
					formatLocale: {
	          			firstDayOfWeek: 1,
	              	},
	              	monthBeforeYear: false,
	          	},
      			errors:{

          		}
			}
		},
		methods: {
			initialize(){
				this.dateEntrance = this.contract.service_chamber_date_entrance_estimated != null? new Date(this.contract.service_chamber_date_entrance_estimated): null;
				this.timeEntrance = this.contract.service_chamber_time_entrance_estimated != null? new Date("1970-01-01 " + this.contract.service_chamber_time_entrance_estimated): null;
				this.dateExit = this.contract.service_chamber_date_exit_estimated != null? new Date(this.contract.service_chamber_date_exit_estimated): null;
				this.timeExit = this.contract.service_chamber_time_exit_estimated != null? new Date("1970-01-01 " + this.contract.service_chamber_time_exit_estimated): null;
			},
			addCoffining(){
				this.$root.$emit('coffining.add');
			},
			changeEntrance(evt){
				if(evt != null){
					this.contract.service_chamber_date_entrance_estimated = moment(evt).format('YYYY-MM-DD');
				}
            	else
					this.contract.service_chamber_date_entrance_estimated = null;
				this.dateExit = null;
				this.contract.service_chamber_date_exit_estimated = null;
				this.contract.service_chamber_time_exit_estimated = null;
			},
			changeEntranceTime(evt){
				if(evt != null)
					this.contract.service_chamber_time_entrance_estimated = moment(evt).format('HH:mm');
	            else
					this.contract.service_chamber_time_entrance_estimated = null;
				this.timeExit = null;
				this.contract.service_chamber_time_exit_estimated = null;
			},
			changeExit(evt){
				if(evt != null){
					var start = moment(this.contract.service_chamber_date_entrance_estimated);
					var end = moment(evt);
					if(start > end){
						this.$swal(
							'¡Atención!',
							'La fecha de salida no puede ser anterior a la de entrada',
							'warning'
						);
						this.dateExit = null;
						return;
					}
					this.contract.service_chamber_date_exit_estimated = moment(evt).format('YYYY-MM-DD');
				}
					
	            else
					this.contract.service_chamber_date_exit_estimated = null;
			},
			changeExitTime(evt){
				if(evt != null){
					var start = moment(this.contract.service_chamber_date_entrance_estimated + " " + this.contract.service_chamber_time_entrance_estimated);
					var aux = moment(evt).format('HH:mm');
					var end = moment(this.contract.service_chamber_date_exit_estimated + " " + aux);
					if(start >= end){
						this.$swal(
							'¡Atención!',
							'La fecha de salida no puede ser anterior a la de entrada',
							'warning'
						);
						this.timeExit = null;
						return;
					}
					this.contract.service_chamber_time_exit_estimated = moment(evt).format('HH:mm');
				}
            	else
					this.contract.service_chamber_time_exit_estimated = null;
			},
			makeOrder(){
				var vm = this;
	  			this.$swal({
					title: "Realizar pedido",
					text: "¿Confirmas que deseas realizar el pedido de adornos florales?",
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Sí',
				}).then((result) => {
                    if(result.value){
                    	this.ordering = true;
                        axios({
		                    method: 'post',
		                    url: this.baseContractOrnaments + '/order/' + this.contract.id,
		                    responseType: 'json',
		                }).then(function(response){
		                	this.ordering = true;
							vm.$swal(
								'¡Correcto!',
								'El pedido se realizó con éxito',
								'success'
							);
		                })
		                .catch(function (error) {
		                    vm.ordering = false;
							var message = 'Parece que algo fue mal';
							if(error.response.status == 400)
								message = error.response.data.message;
							if(error.response.status == 404)
								message = "El registro ya no existe";
							vm.$swal(
								'¡Ups!',
								message,
								'error'
							);
		                });
                    }
				})
			},
			makeReminderOrder(){
				var vm = this;
	  			this.$swal({
					title: "Realizar pedido",
					text: "¿Confirmas que deseas realizar el pedido de recordatorios?",
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Sí',
				}).then((result) => {
                    if(result.value){
                    	this.ordering = true;
                        axios({
		                    method: 'post',
		                    url: this.baseContractReminders + '/order/' + this.contract.id,
		                    responseType: 'json',
		                }).then(function(response){
		                	this.ordering = true;
							vm.$swal(
								'¡Correcto!',
								'El pedido se realizó con éxito',
								'success'
							);
		                })
		                .catch(function (error) {
		                    vm.ordering = false;
							var message = 'Parece que algo fue mal';
							if(error.response.status == 400)
								message = error.response.data.message;
							if(error.response.status == 404)
								message = "El registro ya no existe";
							vm.$swal(
								'¡Ups!',
								message,
								'error'
							);
		                });
                    }
				})
			},
			addOrnament(){
				this.$root.$emit('contract-floral-ornaments.add', {
					contract: this.contract,
					ornament: null
				});
			},
			addReminder(){
				this.$root.$emit('contract-reminders.add', {
					contract: this.contract,
					reminder: null
				});
			},
			editOrnament(o, index){
				this.$root.$emit('contract-floral-ornaments.edit', {
					contract: this.contract,
					ornament: o
				});
			},
			editReminder(r, index){
				this.$root.$emit('contract-reminders.edit', {
					contract: this.contract,
					reminder: r
				});
			},
			updateOrnaments(data){
				var index = -1;
				for(var i in this.floral){
					if(this.floral[i].id == data.id){
						this.$set(this.floral, i, data);
						return ;
					}
				}
				if(index == -1)
					this.floral.push(data);
			},
			updateReminders(data){
				var index = -1;
				for(var i in this.reminders){
					if(this.reminders[i].id == data.id){
						this.$set(this.reminders, i, data);
						return ;
					}
				}
				if(index == -1)
					this.reminders.push(data);
			},
			removeOrnament(row, index){
				var vm=this;
	  			this.$swal({
					title: "Eliminar",
					text: "No podrás deshacer esta acción",
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Sí',
				}).then((result) => {
                    if(result.value){
                    	this.floral.splice(index, 1);
                        axios({
		                    method: 'delete',
		                    url: this.baseContractOrnaments + '/' + row.id,
		                    responseType: 'json',
		                }).then(function(response){
		                	
		                })
		                .catch(function (error) {
		                    vm.$swal(
		                      '¡Ups!',
		                      'Parece que algo fue mal',
		                      'error'
		                    );  
		                });
                    }
				})
			},
			removeReminder(row, index){
				var vm=this;
	  			this.$swal({
					title: "Eliminar",
					text: "No podrás deshacer esta acción",
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Sí',
				}).then((result) => {
                    if(result.value){
                    	this.reminders.splice(index, 1);
                        axios({
		                    method: 'delete',
		                    url: this.baseContractReminders + '/' + row.id,
		                    responseType: 'json',
		                }).then(function(response){
		                	
		                })
		                .catch(function (error) {
		                    vm.$swal(
		                      '¡Ups!',
		                      'Parece que algo fue mal',
		                      'error'
		                    );  
		                });
                    }
				})
			},
		}
	}
</script>