<template>
	<div>
	    <div class="row">
            <div class="col-12 col-md-2">
                <label>Tipo de documento *</label>
                <select class="form-control" v-model="client.document_type" v-bind:class="{'is-invalid':errors.document_type}">
                    <option value="dni">DNI</option>
                    <option value="passport">Pasaporte</option>
                    <option value="other">Otros</option>
                </select>
            </div>
            <div class="col-12 col-md-2">
                <label>Documento de identidad * <i class="fa fa-spin fa-spinner" v-show="checkingCif"></i></label>
                <input spellcheck="true" type="text" class="form-control" v-model="client.dni" placeholder="DNI/NIE/Pasaporte" v-on:change="validateCif()" v-bind:class="{'is-invalid':errors.dni}"/>
            </div>
	        <div class="col-12 col-md-4">
	            <label>Nombre *</label>
	            <input spellcheck="true" type="text" class="form-control" v-model="client.name" v-on:blur="firstLetterOnUpperCase('name')" placeholder="Nombre" v-bind:class="{'is-invalid':errors.name}"/>
	        </div>
	        <div class="col-12 col-md-4">
	            <label>Primer apellido *</label>
	            <input spellcheck="true" type="text" class="form-control" v-model="client.surname" v-on:blur="firstLetterOnUpperCase('surname')" placeholder="Primer apellido" v-bind:class="{'is-invalid':errors.surname}"/>
	        </div>
	        <div class="col-12 col-md-4">
	            <label>Segundo apellido</label>
	            <input spellcheck="true" type="text" class="form-control" v-model="client.lastname" v-on:blur="firstLetterOnUpperCase('lastname')" placeholder="Segundo apellido" v-bind:class="{'is-invalid':errors.lastname}"/>
	        </div>
            <div class="col-12 col-md-4">
                <label>Parentesco *</label>
                <input spellcheck="true" type="text" class="form-control" v-model="client.relation" placeholder="Parentesco" v-bind:class="{'is-invalid':errors.relation}"/>
            </div>
	    </div>
	    <hr class="my-3"/>
	    <div class="row">
	        <div class="col-12">
	            <label>Dirección *</label>
	            <input spellcheck="true" type="text" class="form-control" v-model="client.address" placeholder="Dirección" v-bind:class="{'is-invalid':errors.address}"/>
	        </div>
	        <div class="col-12 col-md-4">
	            <label>Código Postal</label>
	            <input spellcheck="true" type="text" class="form-control" v-model="client.postcode" placeholder="Código postal" v-bind:class="{'is-invalid':errors.postcode}"/>
	        </div>
	        <div class="col-12 col-md-4">
	            <label>Provincia *</label>
	            <input spellcheck="true" type="text" class="form-control" v-model="client.state" placeholder="Provincia" v-bind:class="{'is-invalid':errors.state}"/>
	        </div>
	        <div class="col-12 col-md-4">
	            <label>Población *</label>
	            <input spellcheck="true" type="text" class="form-control" v-model="client.city" placeholder="Población" v-bind:class="{'is-invalid':errors.city}"/>
	        </div>
	    </div>
	    <hr class="my-3"/>
	    <div class="row">
            <div class="col-12 col-md-4">
                <label>Email</label>
                <input spellcheck="true" type="email" class="form-control" v-model="client.email" placeholder="Email"/>
            </div>
            <div class="col-12 col-md-4">
                <label>Teléfono *</label>
                <input spellcheck="true" type="text" class="form-control" v-model="client.phone" placeholder="Teléfono"  v-bind:class="{'is-invalid':errors.phone}"/>
            </div>
            <div class="col-12 col-md-4">
                <label>Teléfono 2</label>
                <input spellcheck="true" type="text" class="form-control" v-model="client.phone_2" placeholder="Teléfono 2"/>
            </div>
        </div>
	</div>
</template>
<script>
	export default{
		props: ['countries'],
		data(){
			return {
                base:window.urls['clients-contract.index'],
				isValidCif: true,
                checkingCif: false,
                saveInterval: null,
				client: {
                    id_nationality: 205,
                    name: null,
                    surname: null,
                    lastname: null,
                    relation: null,
                    document_type: null,
                    dni: null,
                    civil: null,
                    gender: null,
                    type: 'solicitant',
                    birthplace: null,
                    birthdate: null,
                    address: null,
                    postcode: null,
                    city: null,
                    state: null,
                    father: null,
                    mother: null,
                },
				errors: {
					id_nationality: false,
                    name: false,
                    surname: false,
                    lastname: false,
                    relation: false,
                    document_type: null,
                    dni: false,
                    gender: false,
                    type: false,
                    birthplace: false,
                    birthdate: false,
                    address: false,
                    postcode: false,
                    city: false,
                    state: false,
				},
				lang: {
                    formatLocale: {
                      firstDayOfWeek: 1,
                    },
                    monthBeforeYear: false,
                },
			}
		},
        watch: {
            client:{
                deep: true,
                handler(data){
                    var mandatory = [
                        'name',
                        'surname',
                        'relation',
                        'document_type',
                        'dni',
                        'type',
                        'address',
                        'postcode',
                        'city',
                        'state',
                        'phone',
                    ];
                    var filled = 0;
                    var fullfilled = false;
                    for(var i = 0; i < mandatory.length; i++){
                        var index = mandatory[i];
                        if(this.client[index] != null && this.client[index] != ""){
                            if(this.errors[index]) this.errors[index] = false;
                            filled++;
                        }
                    }
                    fullfilled = filled == mandatory.length;
                    if(fullfilled){
                        var vm = this;
                        if(this.saveInterval != null) clearTimeout(this.saveInterval);
                        this.saveInterval = setTimeout(function(){
                            vm.save();
                        }, 1500);
                        
                    }
                }
            }
        },
		mounted(){
			this.$root.$on('form.initialize.client',this.initializeClient);
			this.$root.$on('contracts.section.changed.client',this.validate);
		},
		methods: {
			initializeClient(data){
                if(data && data.solicitant){
                    this.client={
                        id: data.solicitant.id,
                        name: data.solicitant.name,
                        surname: data.solicitant.surname,
                        lastname: data.solicitant.lastname,
                        relation: data.solicitant.relation,
                        document_type: data.solicitant.document_type,
                        dni: data.solicitant.dni,
                        type: data.solicitant.type,
                        birthplace: data.solicitant.birthplace,
                        birthdate: data.solicitant.birthdate,
                        address: data.solicitant.address,
                        postcode: data.solicitant.postcode,
                        city: data.solicitant.city,
                        state: data.solicitant.state,
                        email: data.solicitant.email,
                        phone: data.solicitant.phone,
                        phone_2: data.solicitant.phone_2,
                    };
                    this.isValidCif = true;
                }else{
                    this.client={
                        name: null,
                        surname: null,
                        lastname: null,
                        relation: null,
                        document_type: null,
                        dni: null,
                        type: 'solicitant',
                        address: null,
                        postcode: null,
                        city: null,
                        state: null,
                        email: null,
                        phone: null,
                        phone_2: null
                    };
                    this.birthDate = null;
                }
                this.checkingCif = false;
                this.resetErrors();
            },
            loadClient(data){
                this.client={
                    id: data.id,
                    name: data.name,
                    surname: data.surname,
                    lastname: data.lastname,
                    relation: data.relation,
                    document_type: data.document_type,
                    dni: data.dni,
                    type: data.type,
                    birthplace: data.birthplace,
                    birthdate: data.birthdate,
                    address: data.address,
                    postcode: data.postcode,
                    city: data.city,
                    state: data.state,
                    email: data.email,
                    phone: data.phone,
                    phone_2: data.phone_2,
                };
                this.isValidCif = true;
            },
            resetErrors(){
                this.errors={
                    name: false,
                    surname: false,
                    relation: false,
                    document_type: false,
                    dni: false,
                    type: false,
                    address: false,
                    postcode: false,
                    city: false,
                    state: false,
                    phone: false,
                };
            },
            checkIfNumber(evt){
                var charCode = (evt.which) ? evt.which : evt.keyCode;
                if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                    evt.preventDefault();;
                } else {
                    return true;
                }
            },
            checkCif(){
                var cif = JSON.stringify({ check_cif: this.client.dni });
                var vm = this;
                this.checkingCif = true;
                axios({
                    method: 'get',
                    url: this.base+'/table?query='+cif,
                    responseType: 'json',
                }).then(function(response){
                    vm.checkingCif = false;
                    if(response.data.count == 1){
                        var data = response.data.data[0];
                        vm.loadClient(data);
                    }else{
                        vm.client.id = null;
                    }
                })
                .catch(function (error) {
                    vm.checkingCif = false;
                    vm.$swal(
                        '¡Ups!',
                        'No se pudo cargar la información del servidor',
                        'error'
                    );  
                }); 
            },
            firstLetterOnUpperCase(field){
            	if(this.client[field] == null)
            		return;
                var aux = this.client[field].split(" ");
                var final="";
                for(var i=0;i<aux.length;i++){
                    final+=aux[i].charAt(0).toUpperCase() + aux[i].slice(1);
                    if(i<aux.length-1) final+=" ";
                }
                this.client[field]=final;
            },
            validateCif(){
                this.isValidCif = this.client.dni != null;
                if(this.isValidCif && this.client.id_nationality == 205){
                    var regex = new RegExp('([a-z]|[A-Z]|[0-9])[0-9]{7}([a-z]|[A-Z]|[0-9])');
                    this.isValidCif = this.client.dni.match(regex);
                }
                if(!this.isValidCif){
                    this.showValidCifAlert();
                }
                else{
                    this.checkCif();
                }
                this.errors.dni = !this.isValidCif;
            },
            
            validate(){
                var valid = true;
                var errors = 0;
                this.errors.name = this.client.name == null || this.client.name == "";
                this.errors.surname = this.client.surname == null || this.client.surname == "";
                this.errors.relation = this.client.relation == null || this.client.relation == "";
                this.errors.dni = !this.isValidCif || this.client.dni == null || this.client.dni == "";
                this.errors.document_type = this.client.document_type == null || this.client.document_type =="";
                this.errors.type = this.client.type == null || this.client.type == "";
                
                this.errors.address = this.client.address == null || this.client.address == "";
                this.errors.postcode = this.client.postcode == null || this.client.postcode == "";
                this.errors.city = this.client.city == null || this.client.city == "";
                this.errors.state = this.client.state == null || this.client.state == "";
                this.errors.phone = this.client.phone == null || this.client.phone == "";

                for(var i in this.errors){
                	if(this.errors[i])
                		errors++;
                }
                valid = errors == 0;
                this.$root.$emit('contracts.section.validated',{
                	section: 'client',
                	errors: errors
                });
                if(valid){
                    this.save();
                }
            },
            save(){
                var client = JSON.parse(JSON.stringify(this.client));
                this.$root.$emit('contracts.section.set.client', client)
            }
		}
	}
</script>